<div class="container-fluid">
    <div class="row">
        <div class="col-md-5">
            <div class="login-banner">
                <!-- <img src="../../../../assets/images/login-banner.jpg" alt="login-banner"> -->
                <img src="../../../../assets/aca_images/bmw.jpg" alt="login-banner">
            </div>
        </div>
        <div class="col-md-7">
            <div class="login-field">
                <div class="login-box">
                    <h3>Sign In</h3>
                    <!-- <p class="mb-3">Promoting skilled trades and technologies in Ontario since 1989.</p> -->
                    <form [formGroup]="loginForm" (submit)="login()">
                        <div class="form-group">
                            <label for="email">Email Address *</label>
                            <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""></span>
                            <input type="email" class="form-control" placeholder="Email" id="email"
                                formControlName="email">
                            <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                <span *ngIf="f.email.errors.required">
                                    Email is required.
                                </span>
                                <span *ngIf="f.email.errors.email">
                                    Invalid email.
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Password *</label>
                            <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""></span>
                            <input type="password" class="form-control" placeholder="Password" id="password"
                                formControlName="password">
                            <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                                <span *ngIf="f.password.errors.required">
                                    Password is required.
                                </span>
                                <span *ngIf="f.password.errors.minlength">
                                    Password must contain at least 6 characters.
                                </span>
                            </div>
                        </div>
                        <!-- <div class="forgot-pass text-right" routerLink="/auth/forgot-password">Forgot Password?</div> -->
                        <button type="submit" class="btn btn-primary">Sign In</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>