<section class="token-main-wpr">
    <div class="container-fluid">
        <div class="mt-1">

        </div>
        <div class="row">
            <div class="col-md-12">
                <h2 class="t-heading">Al Qaryah Queue Management System</h2>
            </div>
            <!-- <div class="col-md-6">
                <div class="token-system-img">
                    <img src="../../../assets/aca_images/token-img.jpg" alt="" />
                </div>
            </div> -->
            <div class="col-md-6" *ngFor="let token of tokenList">
                <div class="token-system left">
                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>{{token?.department}}</h2>
                                <h2>{{token?.counterName}}</h2>
                                <h2>{{token?.tokenNo}}</h2>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-md-6">
                <div class="token-system">
                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>CE0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="t-s-box">
                                <h2>Counter 1</h2>
                                <h2>Department</h2>
                                <h2>C0001</h2>
                            </div>
                        </div>
                    </div> -->


                </div>
            </div>
        </div>

    </div>
</section>