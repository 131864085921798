import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class SoldCarsService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    getUserList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    getCombinedComment(data: any) {
        return this.http.post(this.url + 'comment/get-combined', data);
    }

    updateStatus(data: any) {
        return this.http.patch(this.url + 'reciept/updateRecieptStatus', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }
}