<app-layout></app-layout>
<div class="content">
    <div class="row" *ngIf="[3,4].includes(selectedUser?.userType)">
        <div class="col-md-3 mb-4">
            <div class="card">
                <div class="card-body">
                    <h2>AED {{userWalletStatement?.closingBalance}}<span class="color-1"> </span></h2>
                    <p>Wallet</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-4">
            <div class="card">
                <div class="card-body">
                    <h2>AED {{userbusinessStatement?.closingBalance}}<span class="color-2"> </span></h2>
                    <p>Statement</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-4">
            <div class="card">
                <div class="card-body">
                    <h2>AED {{userWalletStatement?.closingBalance -
                        (userbusinessStatement?.closingBalance < 0 ? -1*userbusinessStatement?.closingBalance:
                            userbusinessStatement?.closingBalance)}}<span class="color-3"> </span></h2>

                    <p class="blankLabel">Balance</p>
                </div>
            </div>

        </div>
    </div>
    <div class="page-container-header">
        <h1 class="page-title">user Statement</h1>
    </div>

    <div class="page-container-body">
        <div class="card">
            <div class="card-body">
                <div>
                    <div class="col-sm-12 row">
                        <div class="col-sm-3">
                            <mat-form-field appearance="fill">
                                <input type="text" placeholder="Assignee" aria-label="Assignee" matInput
                                    [formControl]="myControl" [matAutocomplete]="auto2">
                                <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onSelectedSaller($event)"
                                    [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of users" [value]="option">
                                        {{ option.name + '(' + option.phone + ')' }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control mb-4" [(ngModel)]="fromDate">
                        </div>

                        <div class="col-md-3">
                            <input type="date" class="form-control mb-4" [(ngModel)]="toDate">
                        </div>
                        <div class="col-md-2" style="z-index: 0;">
                            <div class="button-sec-filter d-flex">
                                <div class="input-group-append ">
                                    <button id="button-addon5" type="submit" class="btn btn-primary mb-4"
                                        style="height: 35px;" (click)="searchTransaction()"><i
                                            class="fa-solid fa-magnifying-glass"></i>
                                        Search </button>
                                </div>

                                <div class="input-group-append">
                                    <button id="button-addon5" (click)="clearFilter()" type="submit"
                                        class="btn btn-secondary mb-4" style="height: 35px;margin-left: 20px;">
                                        Clear </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="userTypeStatus.buyer.includes(this.selectedUser?.userType)">

        <div class="tab-style-1">
            <ul>
                <li class="active" [ngClass]="{'active' : tab1PanelActive == true}" (click)="tab1Nav()">
                    Statement
                </li>
                <li class="active" [ngClass]="{'active' : tab2PanelActive == true}" (click)="tab2Nav()">
                    Wallet
                </li>
            </ul>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="card-header col-4">
                <h4 class="card-title left-header">User Statement</h4>
            </div>
            <div class="skill-tabs justify-content-between align-items-top mt-2">
                <div class="skill-tab-left">
                    <div class="table-responsive">
                        <table class="table table-style-1 mt-2">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Transaction Date</th>
                                    <th scope="col">Account Name</th>
                                    <th scope="col">Account No</th>
                                    <th scope="col">Voucher No</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Reference</th>
                                    <th scope="col">Reference2.</th>
                                    <th scope="col">DR</th>
                                    <th scope="col">CR</th>
                                    <th scope="col">Running Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="4"></td>
                                    <td>OPENING BALANCE</td>
                                    <td colspan="4"></td>
                                    <td style="font-weight: 100;">
                                        {{statementList?.openingBalance}}
                                    </td>
                                </tr>
                                <tr *ngFor="let acc of statementList?.statement;let index=index">
                                    <td>{{(acc?.transDate | date:'dd-MM-YYYY') ? (acc?.transDate |
                                        date:'dd-MM-YYYY') : 'NA' }}</td>
                                    <td>{{acc?.accountName ? acc?.accountName : 'NA'}}</td>
                                    <td>{{acc?.accountNo ? acc?.accountNo: 'NA'}}</td>
                                    <td>{{acc?.voucherNo ? acc?.voucherNo : 'NA'}}</td>
                                    <td>{{acc?.description}}</td>
                                    <td>{{acc?.referenceNo}}</td>
                                    <td>{{acc?.referenceNo2}}</td>
                                    <td>{{acc?.transactionType=='dr'?acc?.payAmount:''}}</td>
                                    <td>{{acc?.transactionType=='cr'?acc?.payAmount:''}}</td>
                                    <td>{{acc?.runningBalance}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td>CLOSING BALANCE</td>
                                    <td colspan="4"></td>
                                    <td style="font-weight: 100;">
                                        {{statementList?.closingBalance}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>