import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class MenuService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    addMenu(data: any) {
        return this.http.post(this.url + 'master/menuAdd', data);
    }

    updateMenu(data: any) {
        return this.http.patch(this.url + 'master/menuUpdate', data);
    }

    menuList(data: any) {
        return this.http.post(this.url + 'master/menuList', data);
    }

    userRoleList(data: any) {
        return this.http.post(this.url + 'master/userRoleList', data);
    }

    addMenuAccess(data: any) {
        return this.http.post(this.url + 'master/menuAccessAdd', data);
    }

    menuAccessList(data: any) {
        return this.http.post(this.url + 'master/menuAccessList', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }


    deleteMenu(data: any) {

        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'master/delete', httpOptions);
    }


}