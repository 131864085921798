import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class MasterCarService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    updateInventory(data: any) {
        return this.http.patch(this.url + 'inventory/update', data);
    }


    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    addcar(data: any) {
        return this.http.post(this.url + 'inventory/add', data);
    }

    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }
    SalerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    addReservePrice(data: any) {
        return this.http.patch(this.url + 'inventory/update', data);
    }

    updateVinImage(data: any) {
        return this.http.patch(this.url + 'vin/vinImageUpdate', data);
    }


    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    handlingFeeList(data: any) {
        return this.http.post(this.url + 'master/handlingfeelist', data);
    }

    modelList(data: any) {
        return this.http.post(this.url + 'master/modellist', data);
    }

    seriesList(data: any) {
        return this.http.post(this.url + 'master/serieslist', data);
    }

    yearList(data: any) {
        return this.http.post(this.url + 'master/yearlist', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }

    makeList(data: any) {
        return this.http.post(this.url + 'master/list', data);
    }

    engineList(data: any) {
        return this.http.post(this.url + 'master/enginelist', data);
    }

    exteriorColorList(data: any) {
        return this.http.post(this.url + 'master/exteriorcolorlist', data);
    }

    InteriorColorList(data: any) {
        return this.http.post(this.url + 'master/interiorcolorlist', data);
    }

    bodyList(data: any) {
        return this.http.post(this.url + 'master/bodylist', data);
    }

    driveList(data: any) {
        return this.http.post(this.url + 'master/drivetypelist', data);
    }

    fuelList(data: any) {
        return this.http.post(this.url + 'master/fueltypelist', data);
    }

    addReservePriceNew(data: any) {
        return this.http.post(this.url + 'inventory/addreseverprice', data);
    }

    reversePriceList(data: any) {
        return this.http.post(this.url + 'inventory/addreseverpricelist', data);
    }

    carApproved(data: any) {
        return this.http.patch(this.url + 'inventory/inventoryRequestUpdate', data);
    }


    updateReservePrice(data: any) {
        return this.http.patch(this.url + 'inventory/selleracceptanceUpdate', data);
    }

    getpassadd(data: any) {
        return this.http.post(this.url + 'inventory/getpassadd', data);
    }

    getPassList(data: any) {
        return this.http.post(this.url + 'inventory/getPassList', data);
    }

    getePassUpdate(data: any) {
        return this.http.patch(this.url + 'inventory/getpassupdate', data);
    }


    getePassScheduleUpdate(data: any) {
        return this.http.patch(this.url + 'inventory/getpassrescheduleupdate', data);
    }


    getCommentList(data: any) {
        return this.http.post(this.url + 'user/getAllMessage', data)
    }

    getUserList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }
}