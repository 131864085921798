import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ThrowStmt } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class InventoryService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    carApproved(data: any) {
        return this.http.patch(this.url + 'inventory/inventoryRequestUpdate', data);
    }

    addReservePriceNew(data: any) {
        return this.http.post(this.url + 'inventory/addreseverprice', data);
    }

    updateReservePrice(data: any) {
        return this.http.patch(this.url + 'inventory/selleracceptanceUpdate', data);
    }

    getUserList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    getCommentList(data: any) {
        return this.http.post(this.url + 'user/getAllMessage', data)
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    addInventory(data: any) {
        return this.http.post(this.url + 'inventory/add', data);
    }
    importInventory(data: any) {
        return this.http.post(this.url + 'inventory/import', data);
    }

    getInventoryList(data: any) {
        return this.http.post(this.url + 'inventory/list', data)
    }

    assignInventoryPlan(data: any) {
        return this.http.post(this.url + 'inventory/create-inventory-plan', data)
    }

    deleteInventory(data: any) {
        return this.http.post(this.url + 'inventory/delete-inventory ', data)
    }

    withDrawInventory(data: any) {
        return this.http.post(this.url + 'inventory/withdraw-inventory', data)
    }

    getInventoryById(data: any) {
        return this.http.post(this.url + 'inventory/view', data);
    }

    getPurchasedReceivedAging(data: any) {
        return this.http.post(this.url + 'inventory/purchased-received-duration-report', data);
    }

    getUnsoldAging(data: any) {
        return this.http.post(this.url + 'reciept/sold-duration-report', data);
    }

    SalerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    updateInventory(data: any) {
        return this.http.patch(this.url + 'inventory/update', data);
    }


    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    modelList(data: any) {
        return this.http.post(this.url + 'master/modellist', data);
    }

    seriesList(data: any) {
        return this.http.post(this.url + 'master/serieslist', data);
    }

    yearList(data: any) {
        return this.http.post(this.url + 'master/yearlist', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }

    makeList(data: any) {
        return this.http.post(this.url + 'master/list', data);
    }

    engineList(data: any) {
        return this.http.post(this.url + 'master/enginelist', data);
    }

    exteriorColorList(data: any) {
        return this.http.post(this.url + 'master/exteriorcolorlist', data);
    }

    InteriorColorList(data: any) {
        return this.http.post(this.url + 'master/interiorcolorlist', data);
    }

    bodyList(data: any) {
        return this.http.post(this.url + 'master/bodylist', data);
    }

    driveList(data: any) {
        return this.http.post(this.url + 'master/drivetypelist', data);
    }

    fuelList(data: any) {
        return this.http.post(this.url + 'master/fueltypelist', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }


    checkVinNumber(data: any) {
        return this.http.post(this.url + 'inventory/checkUniqueVin', data);
    }

    getTemplist(data: any) {
        return this.http.post(this.url + 'inventory/templist', data);
    }


    moveTempDate(data: any) {
        return this.http.post(this.url + "inventory/movetempdata", data);
    }
    SyncFromSap(data: any) {
        return this.http.post(this.url + "sap/inventoryStatus", data);
    }

    deleteTemplist(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'inventory/templistdelete', httpOptions);

    }

    transverList(data: any) {
        return this.http.post(this.url + 'inventory/inventorytransferlist', data);
    }

    deleteTransverData(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'inventory/inventorytransferdelete', httpOptions);

    }

    transferAdd(data: any) {
        return this.http.post(this.url + 'inventory/inventorytransferadd', data);
    }


    transferUpdate(data: any) {
        return this.http.patch(this.url + 'inventory/inventorytransferupdate', data);
    }

    tempDeleteAll(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'inventory/templistalldelete', httpOptions);
    }

    updateTemplist(data: any) {
        return this.http.patch(this.url + 'inventory/templistupdate', data);
    }

    deleteDuplicateData(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'inventory/duplicatetemplistdelete', httpOptions);
    }

    inventoryCopy(data: any) {
        return this.http.post(this.url + 'inventory/inventoryCopy', data);
    }

    updateCar(data: any) {
        return this.http.patch(this.url + 'inventory/update', data);
    }

    carPlan(data: any) {
        return this.http.post(this.url + 'inventory/carwiseplanList', data);
    }


    getGellery(data: any) {
        return this.http.post(this.url + 'vin/list', data);
    }

    getGelleryByType(data: any) {
        return this.http.post(this.url + 'vin/imagelist', data);
    }

    generateQr(data: any) {
        return this.http.post(this.url + 'inventory/generateQrCode', data);
    }


    unSoldCarMark(data: any) {
        return this.http.patch(this.url + 'inventory/unsoldmark', data);
    }

    sellerPlanMappingList(data: any) {
        return this.http.post(this.url + 'inventory/sellermappingbranchList', data);
    }

    carWisePlanList(data: any) {
        return this.http.post(this.url + 'inventory/carwiseplanList', data);
    }

    gallleryImages(data: any) {
        return this.http.post(this.url + 'vin/imagelist', data);
    }

    defaultImage(data: any) {
        return this.http.patch(this.url + 'inventory/defaultimageupdate', data);
    }


    deleteImage(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'vin/delete', httpOptions);
    }

    downloadZip(data: any) {
        return this.http.post(this.url + 'vin/filezip', data, { responseType: 'blob' });
    }



}