import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate() {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            return true;
        } else {
            this.router.navigate(['auth', 'login']);
            return false;
        }
    }
}
