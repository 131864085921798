import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from "rxjs";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class SharedService {
    url: any = environment.API_URL
    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    uploadVideo(file: any) {
        return this.http.post(this.url + "upload/video", file);
    }

    uploadPdf(file: any) {
        return this.http.post(this.url + "upload/pdf", file);
    }
    dayEndReport(data: any) {
        return this.http.post(this.url + "reciept/dayend-report", data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    getSidebarMenu(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    getClosingDate(data: any): Observable<any> {
        return this.http.post(this.url + 'account/getclosingDate', data);
    }

    getCommentsierarchy(data: any): Observable<any> {
        return this.http.get(this.url + 'comment/get-comment-hierarchy?referenceName=' + data);
    }

    userProfileValidationRemarksAdd(data: any) {
        return this.http.post(this.url + 'user/verification', data);
    }

    walletTransactionPossibilityCheck(data: any) {
        return this.http.post(this.url + 'user/is-wallet-transaction-possible', data);
    }

    getUserStatement(data: any) {
        return this.http.post(this.url + 'account/consumer-statement-admin-view', data);
    }

    sendOtpForWalletCheck(data: any) {
        return this.http.post(this.url + 'user/send-otp', data);
    }

    verifyOtpForWalletCheck(data: any) {
        return this.http.post(this.url + 'user/verify-otp', data);
    }

    uploadVedio(data: any) {
        return this.http.post(this.url + 'upload/video', data);
    }

    private readonly idSubject = new BehaviorSubject<string | null>(null);
    readonly id$ = this.idSubject.asObservable();

    setId(id: string | null): void {
        this.idSubject.next(id);
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet', worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    downloadFile(fileUrl: string): Observable<Blob> {
        return this.http.get(fileUrl, { responseType: 'blob' });
    }

    heightAssigntoTable(divId, adjustable = 0) {
        var height = document.getElementById(divId).clientHeight;
        var screenheight = window.innerHeight;
        return screenheight - (height + adjustable === 0 ? 200 : adjustable);
    }

    removeEmptyValues(data: any) {
        return _.pickBy(data, (value: any) => {
            if (_.isString(value)) {
                return !_.isEmpty(value);
            }
            return !_.isNil(value) || !_.isEmpty(value);
        });
    }

    objectToQueryString(obj) {
        const queryParams = [];

        // for (const key in obj) {
        //     if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
        //         queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        //     }
        // }
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach(value => {
                        queryParams.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(value));
                    });
                } else {
                    queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
                }
            }
        }

        return queryParams.join('&');
    }
}