import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class QuizService {
    url: any = environment.API_URL

    constructor(
        private http: HttpClient
    ) { }

    getQuizList(data: any) {
        return this.http.post(this.url + 'quiz/list', data);
    }

    createQuiz(data: any) {
        return this.http.post(this.url + 'quiz/create', data);
    }

    updateQuiz(data: any) {
        return this.http.patch(this.url + 'quiz/update', data);
    }

    deleteQuiz(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({ }), body: data
        };
       // console.log('data', data);
        return this.http.delete(this.url + 'quiz/delete', httpOptions);
    }

    getQuizCount() {
        return this.http.get(this.url + 'quiz/question/count');
    }

}