import { Component, Renderer2, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  eventsHeading: boolean = false;
  careersHeading: boolean = false;
  sectorHeading: boolean = false;
  profileHeading: boolean = false;
  resourcesHeading: boolean = false;
  newsHeading: boolean = false;
  educationHeading: boolean = false;
  financialHeading: boolean = false;
  quizHeading: boolean = false;
  usersHeading: boolean = false;
  feedbackHeading: boolean = false;
  partnerHeading: boolean = false;
  notificationHeading: boolean = false;
  divisionHeading: boolean = false;
  subDivisionHeading: boolean = false;
  sponsorsHeading: boolean = false;
  annualEventHeading: boolean = false;
  subEventHeading: boolean = false;
  buyerHeading: boolean = false;
  salerHeading: boolean = false;
  inventoryHeading: boolean = false;
  auctionStockHeading: boolean = false;
  soldCarsHeading: boolean = false;
  salerSoldCarsHeading: boolean = false;
  paymentHeading: boolean = false;
  saleApprovalHeading: boolean = false;
  outgoingPaymentHeading: boolean = false;
  receiptHeading: boolean = false;
  documentsHeading: boolean = false;
  gatepassHeading: boolean = false;
  dayendHeading: boolean = false;
  salesReturnHeading: boolean = false;
  addsalesReturnHeading: boolean = false;
  otherIncomeHeading: boolean = false;

  salerDetailsHeading: boolean = false;
  addSalerHeading: boolean = false;
  addexternalSalerHeading: boolean = false;
  externalSalerListHeading: boolean = false;
  vinManagementHeading: boolean = false;
  externalSallerApproved: boolean = false;
  masterCarHeading: boolean = false;
  pendingCarDetails: boolean = false;
  reportsHeading: boolean = false;
  bidHeading: boolean = false;
  cashBankHeading: boolean = false;
  cashHandHeading: boolean = false;
  makeHeading: boolean = false;
  modelHeading: boolean = false;
  seriesHeading: boolean = false;
  yearHeading: boolean = false;
  branchHeading: boolean = false;
  warehouseHeading: boolean = false;
  incommingCaseHeading: boolean = false;
  interiorColorHeading: boolean = false;
  exteriorColorHeading: boolean = false;
  bodyListHeading: boolean = false;
  engineHeading: boolean = false;
  usersRoleHeading: boolean = false;
  buyerLimitHeading: boolean = false;
  auctionFeeHeading: boolean = false;
  menuHeading: boolean = false;
  transmissionHeading: boolean = false;
  driveHeading: boolean = false;
  menuAccessHeading: boolean = false;
  fuelHeading: boolean = false;
  accountHeading: boolean = false;
  taxAmountHeading: boolean = false;
  handlingFeeHeading: boolean = false;
  glAccountHeading: boolean = false;
  securityAmountHeading: boolean = false;
  accountClosingHeading: boolean = false;
  accountClosingAddHeading: boolean = false;
  folderListHeading: boolean = false;
  counterHeading: boolean = false;
  departmentHeading: boolean = false;
  counterMappingHeading: boolean = false;
  tempListHeading: boolean = false;
  inventoryTransverHeading: boolean = false;
  reportAccountHeading: boolean = false;
  tokenListHeading: boolean = false;
  generateTokenHeading: boolean = false;
  tempUpdateHeading: boolean = false;
  outgoingListHeading: boolean = false;
  outgoingListAdd: boolean = false;
  storageInvoice: boolean = false;
  tokenQueue: boolean = false;
  counterAccess: boolean = false;
  addBranchHeading: boolean = false;
  isBodyClassToggled = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = '';
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private renderer: Renderer2
  ) {
    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });
    this._header.eventHeading.subscribe((res) => {
      this.eventsHeading = res;
    });
    this._header.careersHeading.subscribe((res) => {
      this.careersHeading = res;
    });
    this._header.sectorHeading.subscribe((res) => {
      this.sectorHeading = res;
    });
    this._header.profileHeading.subscribe((res) => {
      this.profileHeading = res;
    });
    this._header.resourcesHeading.subscribe((res) => {
      this.resourcesHeading = res;
    });
    this._header.newsHeading.subscribe((res) => {
      this.newsHeading = res;
    });
    this._header.educationHeading.subscribe((res) => {
      this.educationHeading = res;
    });
    this._header.financialHeading.subscribe((res) => {
      this.financialHeading = res;
    });
    this._header.quizHeading.subscribe((res) => {
      this.quizHeading = res;
    });
    this._header.usersHeading.subscribe((res) => {
      this.usersHeading = res;
    });
    this._header.feedbackHeading.subscribe((res) => {
      this.feedbackHeading = res;
    });
    this._header.partnerHeading.subscribe((res) => {
      this.partnerHeading = res;
    });
    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });
    this._header.divisionHeading.subscribe((res) => {
      this.divisionHeading = res;
    });
    this._header.subDivisionHeading.subscribe((res) => {
      this.subDivisionHeading = res;
    });
    this._header.sponsorsHeading.subscribe((res) => {
      this.sponsorsHeading = res;
    });
    this._header.annualEventHeading.subscribe((res) => {
      this.annualEventHeading = res;
    });
    this._header.subEventHeading.subscribe((res) => {
      this.subEventHeading = res;
    });

    this._header.salerHeading.subscribe((res) => {
      this.salerHeading = res;
    });

    this._header.buyerHeading.subscribe((res) => {
      this.buyerHeading = res;
    });

    this._header.inventoryHeading.subscribe((res) => {
      this.inventoryHeading = res;
    });

    this._header.auctionStockHeading.subscribe((res) => {
      this.auctionStockHeading = res;
    });

    this._header.soldCarsHeading.subscribe((res) => {
      this.soldCarsHeading = res;
    });

    this._header.paymentHeading.subscribe((res) => {
      this.paymentHeading = res;
    });
    this._header.saleApprovalHeading.subscribe((res) => {
      this.saleApprovalHeading = res;
    });
    this._header.outgoingPaymentHeading.subscribe((res) => {
      this.outgoingPaymentHeading = res;
    });
    this._header.salerSoldCarsHeading.subscribe((res) => {
      this.salerSoldCarsHeading = res;
    });

    this._header.receiptHeading.subscribe((res) => {
      this.receiptHeading = res;
    });


    this._header.documentsHeading.subscribe((res) => {
      this.documentsHeading = res;
    });

    this._header.gatepassHeading.subscribe((res) => {
      this.gatepassHeading = res;
    });
    this._header.dayendHeading.subscribe((res) => {
      this.dayendHeading = res;
    });
    this._header.salesReturnHeading.subscribe((res) => {
      this.salesReturnHeading = res;
    });
    this._header.addsalesReturnHeading.subscribe((res) => {
      this.addsalesReturnHeading = res;
    });
    this._header.otherIncomeHeading.subscribe((res) => {
      this.otherIncomeHeading = res;
    });

    this._header.salerDetailsHeading.subscribe((res) => {
      this.salerDetailsHeading = res;
    });

    this._header.addSalerHeading.subscribe((res) => {
      this.addSalerHeading = res;
    });

    this._header.addexternalSalerHeading.subscribe((res) => {
      this.addexternalSalerHeading = res;
    });

    this._header.externalSalerListHeading.subscribe((res) => {
      this.externalSalerListHeading = res;
    });

    this._header.vinManagementHeading.subscribe((res) => {
      this.vinManagementHeading = res;
    });

    this._header.externalSallerApproved.subscribe((res) => {
      this.externalSallerApproved = res;
    });

    this._header.masterCarHeading.subscribe((res) => {
      this.masterCarHeading = res;
    });

    this._header.pendingCarDetails.subscribe((res) => {
      this.pendingCarDetails = res;
    });

    this._header.reportsHeading.subscribe((res) => {
      this.reportsHeading = res;
    });

    this._header.bidHeading.subscribe((res) => {
      this.bidHeading = res;
    });


    this._header.cashBankHeading.subscribe((res) => {
      this.cashBankHeading = res;
    });

    this._header.cashHandHeading.subscribe((res) => {
      this.cashHandHeading = res;
    });

    this._header.makeHeading.subscribe((res) => {
      this.makeHeading = res;
    });

    this._header.modelHeading.subscribe((res) => {
      this.modelHeading = res;
    });

    this._header.seriesHeading.subscribe((res) => {
      this.seriesHeading = res;
    });

    this._header.yearHeading.subscribe((res) => {
      this.yearHeading = res;
    });

    this._header.branchHeading.subscribe((res) => {
      this.branchHeading = res;
    });

    this._header.warehouseHeading.subscribe((res) => {
      this.warehouseHeading = res;
    });

    this._header.incommingCaseHeading.subscribe((res) => {
      this.incommingCaseHeading = res;
    });

    this._header.interiorColorHeading.subscribe((res) => {
      this.interiorColorHeading = res;
    });


    this._header.exteriorColorHeading.subscribe((res) => {
      this.exteriorColorHeading = res;
    });

    this._header.bodyListHeading.subscribe((res) => {
      this.bodyListHeading = res;
    });

    this._header.engineHeading.subscribe((res) => {
      this.engineHeading = res;
    });

    this._header.usersRoleHeading.subscribe((res) => {
      this.usersRoleHeading = res;
    });

    this._header.buyerLimitHeading.subscribe((res) => {
      this.buyerLimitHeading = res;
    });

    this._header.auctionFeeHeading.subscribe((res) => {
      this.auctionFeeHeading = res;
    });

    this._header.menuHeading.subscribe((res) => {
      this.menuHeading = res;
    });

    this._header.transmissionHeading.subscribe((res) => {
      this.transmissionHeading = res;
    });


    this._header.driveHeading.subscribe((res) => {
      this.driveHeading = res;
    });


    this._header.menuAccessHeading.subscribe((res) => {
      this.menuAccessHeading = res;
    });

    this._header.fuelHeading.subscribe((res) => {
      this.fuelHeading = res;
    });

    this._header.accountHeading.subscribe((res) => {
      this.accountHeading = res;
    });


    this._header.taxAmountHeading.subscribe((res) => {
      this.taxAmountHeading = res;
    });

    this._header.handlingFeeHeading.subscribe((res) => {
      this.handlingFeeHeading = res;
    });

    this._header.glAccountHeading.subscribe((res) => {
      this.glAccountHeading = res;
    });

    this._header.securityAmountHeading.subscribe((res) => {
      this.securityAmountHeading = res;
    });

    this._header.accountClosingHeading.subscribe((res) => {
      this.accountClosingHeading = res;
    });

    this._header.accountClosingAddHeading.subscribe((res) => {
      this.accountClosingAddHeading = res;
    });


    this._header.folderListHeading.subscribe((res) => {
      this.folderListHeading = res;
    });

    this._header.counterHeading.subscribe((res) => {
      this.counterHeading = res;
    });


    this._header.departmentHeading.subscribe((res) => {
      this.departmentHeading = res;
    });

    this._header.counterMappingHeading.subscribe((res) => {
      this.counterMappingHeading = res;
    });

    this._header.tempListHeading.subscribe((res) => {
      this.tempListHeading = res;
    });

    this._header.inventoryTransverHeading.subscribe((res) => {
      this.inventoryTransverHeading = res;
    });

    this._header.reportAccountHeading.subscribe((res) => {
      this.reportAccountHeading = res;
    });


    this._header.tokenListHeading.subscribe((res) => {
      this.tokenListHeading = res;
    });


    this._header.generateTokenHeading.subscribe((res) => {
      this.generateTokenHeading = res;
    });


    this._header.tempUpdateHeading.subscribe((res) => {
      this.tempUpdateHeading = res;
    });

    this._header.outgoingListHeading.subscribe((res) => {
      this.outgoingListHeading = res;
    });

    this._header.outgoingListAdd.subscribe((res) => {
      this.outgoingListAdd = res;
    });


    this._header.storageInvoice.subscribe((res) => {
      this.storageInvoice = res;
    });

    this._header.tokenQueue.subscribe((res) => {
      this.tokenQueue = res;
    });


    this._header.counterAccess.subscribe((res) => {
      this.counterAccess = res;
    });

    this._header.addBranchHeading.subscribe((res) => {
      this.addBranchHeading = res;
    });



  }

  userName: String;


  ngOnInit(): void {
    this.userName = this.authService?.currentUserValue?.roleName;
    // let user = JSON.parse(localStorage.getItem('currentUser'));
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('auctionList');
    localStorage.removeItem('list');

    localStorage.removeItem('lang');
    this.modalRef.hide();
    this.router.navigateByUrl('/auth/login')
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  englishSelection() {
    this.default = true;
    this.lang = 'English';
    localStorage.setItem('lang', 'eng');
    window.location.reload();
    // console.log('this.returnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

  frenchSelection() {
    this.default = false;
    this.lang = 'Français';
    localStorage.setItem('lang', 'fre');
    window.location.reload();
    // console.log('this.rturnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }


  toggleExpendedMenu() {
    this.isBodyClassToggled = !this.isBodyClassToggled;
    if (this.isBodyClassToggled) {
      this.renderer.addClass(document.body, 'menu-sidebar--expended'); // Replace 'your-toggle-class' with the class you want to add.
    } else {
      this.renderer.removeClass(document.body, 'menu-sidebar--expended');
    }
  }

}
