import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class ReceiptService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    registerBuyer(data: any) {
        return this.http.post(this.url + 'user/register', data);
    }

    buyerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    auctionList(data: any) {
        return this.http.post(this.url + 'auction/list', data)
    }

    newAuctionList(data: any) {
        return this.http.post(this.url + 'auction/auctionlist', data)
    }

    auctionVehicleScreening(data: any) {
        return this.http.post(this.url + 'auction/vehicle-screening', data)
    }

    searchVin(data: any) {
        return this.http.post(this.url + 'auction/searchVinAuction', data);
    }

    createReceipt(data: any) {
        return this.http.post(this.url + 'reciept/add', data);
    }

    UpdateBidFinal(data: any, id: any) {
        return this.http.patch(this.url + `bid/update-bid-history?id=${id}`, data);
    }

    AddCommentReceipt(data: any) {
        return this.http.post(this.url + 'comment/create', data);
    }

    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    receiptTypeTaxList(data: any) {
        return this.http.get(this.url + `reciept/type-tax?search=${data}`);
    }

    convertReceiptTypeTax(data: any) {
        return this.http.post(this.url + `reciept/type-tax`, data);
    }

    SalerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    addPayment(data: any) {
        return this.http.post(this.url + 'reciept/addLedger', data);
    }
    addDiscount(data: any) {
        return this.http.patch(this.url + 'reciept/updateDiscount', data);
    }
    deleteReciept(data: any) {
        return this.http.delete(this.url + 'reciept/delete', data);
    }

    addSalesReturn(data: any) {
        return this.http.post(this.url + 'reciept/addSalesReturn', data);
    }

    getReceiptVoucher(data: any) {
        return this.http.post(this.url + 'reciept/get-receipt-voucher', data);
    }

    getAuctionServiceInvoice(data: any) {
        return this.http.post(this.url + 'reciept/get-receipt-auction-charge-invoice', data);
    }

    salesReturnlist(data: any) {
        return this.http.post(this.url + 'reciept/salesReturnlist', data);
    }
    updateSalesReturn(data: any) {
        return this.http.patch(this.url + 'reciept/updateSalesReturn', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    checkSaleDate(data: any) {
        return this.http.post(this.url + 'inventory/checksaledate', data);
    }


}