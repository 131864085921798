import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  currentUser: any;
  allMenu: any;
  checkAdmin: boolean;
  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    console.log(this.currentUser);
    this.getSidebarMenu(this.currentUser?.roleId);

    if (this.currentUser?.roleName.toLowerCase() == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
    }

  }
  getSidebarMenu(userRoleId: any) {
    this.sharedService.getSidebarMenu({ "page": 1, "pageLimit": 200, "listType": "sidebar", "userRoleId": userRoleId })
      .subscribe(
        (res: any) => {
          if (res.status == true) {
            this.allMenu = res.result;
          }
        }
      )
  }
}
