import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: "[DateInputRestrictionDirective]",
})
export class DateInputRestrictionDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // Prevent manual entry for date input fields
    event.preventDefault();
  }

  onKeyUp(event: KeyboardEvent) {

    // Prevent manual entry for date input fields
    event.preventDefault();
  }
}
