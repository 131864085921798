import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class OutgoingCashService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    registerBuyer(data: any) {
        return this.http.post(this.url + 'user/register', data);
    }

    outgoingCashList(data: any) {
        return this.http.post(this.url + 'account/outgoingcashlist', data);
    }

    outgoingCashAdd(data: any) {
        return this.http.post(this.url + 'account/outgoingcashadd', data);
    }

    outgoingCashUpdate(data: any) {
        return this.http.patch(this.url + 'account/outgoingcashupdate', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    accountList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    deleteOutgoind(id: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: id
        };
        return this.http.delete(this.url + 'account/outgoingcashdelete', httpOptions);
    }
}