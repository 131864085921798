import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PolicyComponent } from './views/policy/policy.component';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';
import { TokenSystemComponent } from './views/token-system/token-system.component';
import { GenerateTokenComponent } from './views/generate-token/generate-token.component';
import { UserStatementComponent } from './views/user-statement/user-statement.component';


const routes: Routes = [

  {
    path: 'privacy-policy', component: PolicyComponent
  },
  { path: 'token-system', component: TokenSystemComponent },
  { path: 'user-statement', component: UserStatementComponent },
  { path: 'generate-token', component: GenerateTokenComponent },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'edit-profile', component: EditProfileComponent },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'quiz',
        loadChildren: () =>
          import('./views/quiz/quiz.module').then((m) => m.QuizModule),

      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),

      },

      {
        path: 'saler',
        loadChildren: () =>
          import('./views/saler/saler.module').then((m) => m.SalerModule),
      },

      {
        path: 'buyer',
        loadChildren: () =>
          import('./views/buyer/buyer.module').then((m) => m.BuyerModule),
      },

      {
        path: 'sold/cars',
        loadChildren: () =>
          import('./views/sold-cars/sold-cars.module').then((m) => m.SoldCarsModule),
      },

      {
        path: 'inventory',
        loadChildren: () =>
          import('./views/inventory/inventory.module').then((m) => m.InventoryModule),
      },

      {
        path: 'auction/stock',
        loadChildren: () =>
          import('./views/auction-stock/auction-stock.module').then((m) => m.AuctionStockModule),
      },

      {
        path: 'receipt',
        loadChildren: () =>
          import('./views/receipt/receipt.module').then((m) => m.ReceiptModule),
      },


      {
        path: 'partners',
        loadChildren: () =>
          import('./views/partners/partners.module').then((m) => m.PartnersModule),

      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },

      {
        path: 'documents',
        loadChildren: () =>
          import('./views/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: 'dayend',
        loadChildren: () =>
          import('./views/dayend/dayend.module').then(
            (m) => m.DayendModule
          )
      },

      {
        path: 'gatepass',
        loadChildren: () =>
          import('./views/gatepass/gatepass.module').then(
            (m) => m.GatepassModule
          ),
      },

      {
        path: 'master-car',
        loadChildren: () =>
          import('./views/master-car/master-car.module').then(
            (m) => m.MsterCarModule
          ),
      },


      // {
      //   path: 'expense',
      //   loadChildren: () =>
      //     import('./views/expense/expense.module').then(
      //       (m) => m.ExpenseModule
      //     ),
      // },



      {
        path: 'vin/management',
        loadChildren: () =>
          import('./views/vin-management/vin-management.module').then(
            (m) => m.VinManagementModule
          ),
      },

      {
        path: 'reports',
        loadChildren: () =>
          import('./views/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },

      {
        path: 'bid',
        loadChildren: () =>
          import('./views/bid/bid.module').then((m) => m.BidModule)
      },

      {
        path: 'master',
        loadChildren: () =>
          import('./views/master-all/master-all.module').then((m) => m.MasterAllModule)
      },


      {
        path: 'incomming-case',
        loadChildren: () =>
          import('./views/incomming-case/incomming-case.module').then((m) => m.IncommingCaseModule)
      },



      {
        path: 'menu',
        loadChildren: () =>
          import('./views/menu/menu.module').then((m) => m.MenuModule)
      },

      {
        path: 'account',
        loadChildren: () =>
          import('./views/account/account.module').then((m) => m.AccountModule)
      },

      {
        path: 'outgoing-cash',
        loadChildren: () =>
          import('./views/outgoing-cash/outgoing-cash.module').then((m) => m.OutgoingCashModule)
      },




      {
        path: 'qwaiting',
        loadChildren: () =>
          import('./views/qwaiting/qwaiting.module').then((m) => m.QwaitingModule)
      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '***', redirectTo: 'seller', pathMatch: 'full' },
    ]
  },
  // { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
