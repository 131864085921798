import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";

@Injectable()

export class BuyerService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    receiptBuyerUpdate(id: any, postData: any) {
        return this.http.patch<any>(this.url + `reciept/update-buyer/${id}`, postData);
    }


    registerBuyer(data: any) {
        return this.http.post(this.url + 'user/register', data);
    }

    createBusinessPartner(data: any) {
        return this.http.post(this.url + 'sap/businesspartener', data);
    }

    buyerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    userCreaditHistory(data: any) {
        return this.http.post(this.url + 'order/credit-list', data);
    }

    buyerCreaditHistory(data: any) {
        return this.http.post(this.url + 'order/buyer-credit-history', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    changePassword(data: any) {
        return this.http.patch(this.url + 'user/changePasswordByAdmin', data);
    }

    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    auctionVehicleList(data: any) {
        return this.http.post(this.url + 'auction/auctionvehicleslist', data)
    }


    watchList(data: any) {
        return this.http.post(this.url + 'inventory/watchlistlist', data);
    }

    approveReject(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    forcefullyLogout(data: any) {
        return this.http.post(this.url + 'user/force-logout-through-event', data);
    }

    accountGlListReport(data: any) {
        return this.http.post(this.url + 'account/glListReport', data);
    }

    orderHistoryList(data: any) {
        return this.http.post(this.url + 'order/list', data);
    }

    passList(data: any) {
        return this.http.post(this.url + 'inventory/getPassList', data);
    }

    buyerAnalytics(data: any) {
        return this.http.post(this.url + 'order/buyer-analytics', data);
    }


}
