import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class QwaitingService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }


    addCounter(data: any) {
        return this.http.post(this.url + 'qwaiting/counterAdd', data);
    }

    updateCounter(data: any) {
        return this.http.patch(this.url + 'qwaiting/counterUpdate', data);
    }

    counterList(data: any) {
        return this.http.post(this.url + 'qwaiting/counterList', data);
    }

    deleteCounter(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'qwaiting/counterDelete', httpOptions);
    }

    addDepartment(data: any) {
        return this.http.post(this.url + 'qwaiting/departmentAdd', data);
    }

    updateDepartment(data: any) {
        return this.http.patch(this.url + 'qwaiting/departmentUpdate', data);
    }

    departmentList(data: any) {
        return this.http.post(this.url + 'qwaiting/departmentList', data);
    }

    deleteDepartment(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'qwaiting/departmentDelete', httpOptions);
    }



    addCounterMapping(data: any) {
        return this.http.post(this.url + 'qwaiting/counterMappingAdd', data);
    }

    updateCounterMapping(data: any) {
        return this.http.patch(this.url + 'qwaiting/counterMappUpdate', data);
    }

    counterMappingList(data: any) {
        return this.http.post(this.url + 'qwaiting/mappingList', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    getTokenList(data: any) {
        return this.http.post(this.url + 'qwaiting/tokenList', data);
    }

    generateToken(data: any) {
        return this.http.post(this.url + 'qwaiting/generateToken', data);
    }

    tokenAction(data: any) {
        return this.http.post(this.url + 'qwaiting/tokenAction', data);
    }


    tokenAccessList(data: any) {
        return this.http.post(this.url + 'user/qwaitingUserAccesslist', data);
    }

    getUserList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    userRoleList(data: any) {
        return this.http.post(this.url + 'master/userRoleList', data);
    }

    registerUser(data: any) {
        return this.http.post(this.url + 'user/reg-admin', data);
    }

    updateToken(data: any) {
        return this.http.patch(this.url + 'qwaiting/tokenUpdate', data);
    }

    changePassword(data: any) {
        return this.http.patch(this.url + 'user/changePasswordByAdmin', data);
    }


}