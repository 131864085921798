<div class="header">
  <div class="row">
    <div class="header__left left-sec">
      <div class="menu-icon" (click)="toggleExpendedMenu()">
        <i class="fa fa-bars m-open" aria-hidden="true"></i>
        <i class="fa fa-arrow-left m-close" aria-hidden="true"></i>
      </div>
      <div class="logo"><img routerLink="/dashboard" src="../../../assets/aca_images/logo.png" alt="logo"></div>
    </div>

    <div class="right-sec">

      <div class="btn-group" dropdown placement="bottom right">
        <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle fontchange"
          aria-controls="dropdown-alignment">
          <!-- <img  alt="" /> -->
          {{ userName }}
          <i class="fa-solid fa-chevron-down"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
          aria-labelledby="button-alignment">
          <li role="menuitem">
            <a class="dropdown-item" style="background: #346ef6; color: #fff" routerLink="/edit-profile">Edit
              Profile</a>
          </li>
          <li style="cursor: pointer" role="menuitem">
            <a class="dropdown-item" (click)="openModal(template)">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 footer-message ">
      <marquee><b>Our ERP system will be transferred from the current link to a new link <a
            href="https://crm.alqaryahauction.com/" target="_balnk">New erp Link</a>in the coming few days. This upgrade
          aims to enhance our system's performance and provide a better user experience. Please log in to the new
          ERP link with existing login credentials and verify that all your work, data, and functionalities are
          working as
          expected.</b></marquee>
    </div>
  </div>
</div>


<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="" />
    <p>Are you sure you <br />want to logout</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000">
        No
      </button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>
</ng-template>