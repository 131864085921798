import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class VinManagementService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }


    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    addVin(data: any) {
        return this.http.post(this.url + 'vin/add', data);
    }

    getVinList(data: any) {
        return this.http.post(this.url + 'vin/list', data);
    }

    updateVin(data: any) {
        return this.http.patch(this.url + 'vin/vinImageUpdate', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    folderList(data: any) {
        return this.http.post(this.url + 'vin/folderList', data);
    }

    addFolder(data: any) {
        return this.http.post(this.url + 'vin/folderAdd', data);
    }

    updateFolder(data: any) {
        return this.http.patch(this.url + 'vin/folderUpdate', data);
    }
}