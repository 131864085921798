import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable()
export class AuctionStockService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient,
    private authService: AuthService
  ) {
  }

  getUserList(data: any) {
    return this.http.post(this.url + 'user/list', data);
  }

  callBidLogHistoryAPi() {
    return this.http.get(this.url + 'bid/create-bid-log');
  }

  updateUser(data: any) {
    return this.http.patch(this.url + 'user/update', data);
  }

  auctionList(data: any) {
    return this.http.post(this.url + 'auction/list', data);
  }

  auctionDetail(data: any) {
    return this.http.post(this.url + 'auction/get-auction', data);
  }

  bidUpdateHistory(data: any, id: any, auctionId: any) {
    return this.http.patch(
      this.url + `bid/update-bid-history?id=${id}&auctionId={auctionId}`,
      data
    );
  }

  auctionListOnly(data: any) {
    return this.http.post(this.url + 'auction/auctionlist', data);
  }

  addPreviousUnsoldvehicle(data: any) {
    return this.http.post(this.url + 'auction/add-previous-unsold-inventories', data);
  }

  addAuction(data: any) {
    return this.http.post(this.url + 'auction/add', data);
  }

  updateAuction(data: any) {
    return this.http.patch(this.url + 'auction/update', data);
  }

  activateOrDeactivateAuction(id: any, data: any) {
    return this.http.patch(this.url + `auction/simple-update/${id}`, data);
  }

  rejectVehicle(data: any, comment: any) {
    return this.http.patch(this.url + `bid/reject-bid?id=${data}`, comment);
  }

  auctionProfitAnalysis(data: any) {
    return this.http.post(this.url + `auction/profit-report-analysis`, data);
  }

  reAuctionVehicle(data: any) {
    return this.http.post(this.url + `auction/addmore`, data);
  }

  removeAuctionVehicle(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}),
      body: data,
    };
    return this.http.delete(
      this.url + 'auction/removeAuctionVehicle ',
      httpOptions
    );
  }

  branchList(data: any) {
    return this.http.post(this.url + 'master/branchlist', data);
  }

  checkAccess(data: any) {
    return this.http.post(this.url + 'master/sidebarMenuAcess', data);
  }

  addMoreAuction(data: any) {
    return this.http.post(this.url + 'auction/addmore', data);
  }

  addWatchList(data: any) {
    return this.http.post(this.url + 'inventory/watchlistadd', data);
  }

  updateReservePrice(data: any) {
    return this.http.patch(this.url + 'bid/update-reserve-price', data);
  }

  auctionVehicleList(data: any) {
    return this.http.post(this.url + 'auction/auctionvehicleslist', data);
  }

  gerReverseBidValue(data: any) {
    return this.http.post(this.url + 'bid/reverse-bid-val', data);
  }

  auctionUpdateDisplayNo(data: any) {
    return this.http.patch(this.url + 'auction/auctionVehicleUpdate', data);
  }

  updateSequence(data: any) {
    return this.http.post(this.url + 'auction/update-sequence', data);
  }

  allMarkUnsold(data: any) {
    return this.http.patch(this.url + 'auction/allMarkUnsold', data);
  }

  auctionReportList(data: any) {
    return this.http.post(this.url + 'auction/auctionvehiclesReport', data);
  }

  auctionHistory(data: any) {
    return this.http.post(this.url + 'auction/auctionvehiclesHistory', data);
  }

  auctionParticipent(data: any) {
    return this.http.get(this.url + `auction/participants?${data}`);
  }

  SalerList(data: any) {
    return this.http.post(this.url + 'user/list', data);
  }

  convertReceipt(data: any) {
    return this.http.post(this.url + 'reciept/convertReciept', data);
  }

  uploadFile(file: any) {
    return this.http.post(this.url + 'upload/file', file);
  }

  generatePdfFile(data: any) {
    return this.http.post(this.url + 'upload/create-pdf', data);
  }

  auctionDisplayNoChangeImportFile(data: any) {
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/json');
    return this.http.post(this.url + 'auction/import-auction-sequence', data, {
      headers: header,
    });
  }

  eventDataObserveable: Subject<boolean> = new Subject<boolean>();
  sseUserLogs() {
    const currentUser = this.authService.currentUserValue;
    let lang = localStorage.getItem('lang');
    let thid = this;
    fetchEventSource(this.url + 'events/user-stream?name=admin', {
      method: 'GET',
      headers: {
        Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done"),
        'x-access-token': currentUser.token,
        'lang': lang ? lang : ''
      },
      onerror(err) {

      },
      onmessage(event: any) {
        const parsedData = event.data ? JSON.parse(event.data) : '';
        if (parsedData != '') {
          if (parsedData?.eventName == 'AUCTION_PARTICIPANT_REFRESH' && parsedData?.success == true) {
            thid.eventDataObserveable.next(parsedData);
          }
        }
      },
    });
  }

}
