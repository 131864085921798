import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class DocumentsService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    registerSaler(data: any) {
        return this.http.post(this.url + 'user/register', data);
    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }


    SalerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    getPassList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    updateStatus(data: any) {
        return this.http.patch(this.url + 'reciept/updateRecieptStatus', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }


    getDocumentList(data: any) {
        return this.http.post(this.url + 'inventory/getdocumentList', data);
    }

    updateDocument(data: any) {
        return this.http.patch(this.url + "inventory/getdocumentupdate", data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    getComments(data: any) {
        return this.http.post(this.url + "user/getAllMessage", data);
    }


    incomingDocuments(data: any) {
        return this.http.post(this.url + "inventory/incomingdocumentpass", data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }

    addDocumentTransfer(data: any) {
        return this.http.post(this.url + "inventory/documenttransferadd", data)
    }

    getDocumentTransferList(data: any) {
        return this.http.post(this.url + "inventory/documenttransferlist", data)
    }

    updateDocumentTransfer(data: any) {
        return this.http.patch(this.url + 'inventory/documenttransferupdate', data);
    }
}