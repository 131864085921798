import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable()
export class PartnerService {
    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    createPartner(data: any) {
        return this.http.post(this.url + 'event/create', data);
    }

    getPartner(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    updatePartner(data: any) {
        return this.http.patch(this.url + 'event/update', data);
    }

    deletePartner(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }
}