import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { QwaitingService } from '../qwaiting/services/qwaiting.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { jsPDF } from 'jspdf';
import screenfull from 'screenfull';


@Component({
  selector: 'app-users-lists',
  templateUrl: './generate-token.component.html',
  styleUrls: ['./generate-token.component.scss'],
})
export class GenerateTokenComponent implements OnInit {
  currentPage: number = 1;
  page: number = 1;
  totalCount: number;
  itemsPerPage: number = 20;
  notFound: boolean = false;
  tokenList: any = [];
  giveAccess: any;
  currentUser: any;
  checkAdmin: any;
  modalRef: BsModalRef;
  departmentId: string;
  mainDepartmentSection: boolean = true;
  subDepartmentSection: boolean = false;
  genretedToken: boolean = false;
  mainDepartmentSectionCard: boolean = true;
  selectedBranchId: any;

  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('addMakeModal') addMakeModal: TemplateRef<any>;
  @ViewChild("receipt", { static: false }) el!: ElementRef;



  constructor(
    private _header: HeaderService,
    private service: QwaitingService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) { }

  // header heading
  ngOnInit(): void {
    this._header.generateTokenHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.gettokenList(this.page, this.itemsPerPage);
    // this.getBranchList();

    if (this.currentUser?.roleName.toLowerCase() == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
    }

    this.route.queryParams.subscribe((param) => {
      this.selectedBranchId = param['id'];
    })
    if (this.selectedBranchId) {
      this.getDepartmentList(this.selectedBranchId);
    }



    // this.getAccess(this.currentUser.roleId, this.router.url);
  }

  ngOnDestroy() {
    this._header.generateTokenHeading.next(false);
  }
  // delete modal
  toggleFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }



  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    this.gettokenList(event.page, event.itemsPerPage);

  }

  gettokenList(page, pageLimit) {
    this.service.departmentList({ "page": page, "pageLimit": pageLimit }).subscribe((res: any) => {
      if (res.status == true) {
        this.tokenList = res.result;
        this.totalCount = res.totalCount;
        if (res.totalCount == 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }
      }
    })
  }

  getAccess(roleId, link) {
    let data = {
      "listType": 'access',
      "userRoleId": roleId,
      "menuLink": link,
      "pageLimit": 100,
      "page": 1
    }
    this.service.checkAccess(data).subscribe((res: any) => {
      if (res.status == true) {
        this.giveAccess = res.result[0];
      }
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  generateToken(id: any) {
    this.departmentId = id;
    this.openModal(this.template2);
  }

  generate(id) {
    this.service.generateToken({ "departmentId": id }).subscribe((res: any) => {
      if (res.status == true) {
        this.modalRef.hide();
        this.toastr.success(res.message);
        this.departmentId = '';
        (<HTMLInputElement>document.getElementById('printToken')).click();
        // this.router.navigateByUrl('/qwaiting/token-list');
      } else {
        this.toastr.error(res.message);
      }
    })
  }

  confirm() {
    this.generate(this.departmentId);
  }


  departmentNameShow: any;
  childDepartmentList: any = [];
  subDepartment(e: any, departmentName: any) {
    this.getChildDepartments(e);
    this.departmentNameShow = departmentName;
    this.subDepartmentSection = false;
    this.mainDepartmentSection = true;
  }


  getChildDepartments(id: any) {
    this.service.departmentList({ "page": 1, "pageLimit": 1000, "parentId": id }).subscribe((res: any) => {
      if (res.status == true) {
        this.childDepartmentList = res.result;
        if (res.totalCount == 0) {
          this.genretedTokenTab(1);
          this.subDepartmentSection = false;
          this.mainDepartmentSection = true;
          this.generateTokenChild(id);
        }

      }
    })
  }



  mainDepartment(e) {
    this.subDepartmentSection = false;
    this.mainDepartmentSection = true;
  }
  genretedTokenTab(e) {
    this.subDepartmentSection = false;
    this.genretedToken = true;
    this.mainDepartmentSectionCard = true;
  }

  departmentList: any = [];
  getDepartmentList(branchId: any) {
    this.service.departmentList({ "page": 1, "pageLimit": 1000, "type": 'parent', 'branchId': branchId }).subscribe((res: any) => {
      if (res.status == true) {
        this.departmentList = res.result;
      }
    })
  }


  generatedToken: any;
  generateTokenChild(id: any) {
    this.service.generateToken({ "departmentId": id, "branchId": this.selectedBranchId }).subscribe((res: any) => {
      if (res.status == true) {
        this.generatedToken = res.result;
        // this.modalRef.hide();
        let tokenDate1 = new Date(this.generatedToken?.createdAt);
        let tokenDate = new Date(tokenDate1.setHours(tokenDate1.getHours()));
        (<HTMLInputElement>document.getElementById('tokenText')).innerHTML = "Token : " + this.generatedToken?.tokenNo;
        (<HTMLInputElement>document.getElementById('departmentText')).innerHTML = this.departmentNameShow;
        (<HTMLInputElement>document.getElementById('dateText')).innerHTML = tokenDate.toDateString() + ' ' + tokenDate.getHours() + ':' + tokenDate.getMinutes();

        this.toastr.success(res.message);
        (<HTMLInputElement>document.getElementById('printToken')).click();

        // this.departmentId = '';
        // this.router.navigateByUrl('/qwaiting/token-list');
      } else {
        this.toastr.error(res.message);
      }
    })
  }


  createPdf() {
    let pdf = new jsPDF('p', 'pt', 'a3');
    pdf.html(this.el.nativeElement, {
      callback: (pdf) => {
        pdf.save('receipt.pdf');
      }
    })
  }

  clicknewToken() {
    location.reload();
  }


  selectedBranch(branch: any) {
    this.selectedBranchId = branch;
    this.getDepartmentList(branch);
  }

  branchList: any = [];
  getBranchList() {
    this.service.branchList({ "page": 1, "pageLimit": 1000 }).subscribe((res: any) => {
      if (res.status == true) {
        this.branchList = res.result;
        this.selectedBranchId = this.branchList[0]?._id;
        this.getDepartmentList(this.selectedBranchId);
      }
    })
  }

}
