import { Component, OnInit } from '@angular/core';
import { QwaitingService } from '../qwaiting/services/qwaiting.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-token-system',
  templateUrl: './token-system.component.html',
  styleUrls: ['./token-system.component.scss']
})
export class TokenSystemComponent implements OnInit {

  constructor(
    private service: QwaitingService,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute
  ) { }

  tokenList: any = [];
  totalCount: boolean;
  notFound: boolean;
  branchId: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      this.branchId = param['id'];
    })
    this.gettokenList();
  }


  gettokenList() {
    let x = setInterval(() => {
      this.spinner.show();
      this.service.getTokenList({ "page": 1, "pageLimit": 1000, "queueStatus": 1, "branchId": this.branchId }).subscribe((res: any) => {
        if (res.status == true) {
          this.tokenList = res.result;
          for (var i of this.tokenList) {

            if (i.call == 1) {
              this.onClickCall(i)
            }
          }
          this.totalCount = res.totalCount;
          if (res.totalCount == 0) {
            this.notFound = true;
          } else {
            this.notFound = false;
          }
        }
      })
      this.spinner.hide();
    }, 3000)
  }


  onClickCall(token) {

    this.spinner.show();
    this.service.updateToken({ "tokenId": token._id, "call": 0 }).subscribe((res: any) => {
      if (res.status == true) {
      }
    })
    this.spinner.hide();
    this.speech('Token number ');
    for (var i of token.tokenNo) {
      if (i == '0' || i == '1' || i == '2' || i == '3' || i == '4' || i == '5' || i == '6' || i == '7' || i == '8' || i == '9') {
        this.speech(parseInt(i));
      } else {
        this.speech(i);
      }
    }
  }

  speech(text) {
    let data = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(data)
  }

}
