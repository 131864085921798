import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class GatepassService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    getPassList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    updateStatus(data: any) {
        return this.http.patch(this.url + 'reciept/updateRecieptStatus', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }


    passList(data: any) {
        return this.http.post(this.url + 'inventory/getPassList', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    updateGatepass(data: any) {
        return this.http.patch(this.url + "inventory/getpassupdate", data);
    }

    incomingGatepass(data: any) {
        return this.http.post(this.url + 'inventory/incominggetpass', data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }

    transferAdd(data: any) {
        return this.http.post(this.url + 'inventory/internalTransfer', data);
    }

    transverList(data: any) {
        return this.http.post(this.url + 'inventory/inventorytransferlist', data);
    }
    transferListUpdate(data: any) {
        return this.http.patch(this.url + 'inventory/inventorytransferupdate', data);
    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

}