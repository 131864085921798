<div class="sidebar">
    <!-- <div class="logo"><img routerLink="/dashboard" src="../../../assets/aca_images/logo.png" alt="logo"></div> -->
    <div class="sidebar-menu">
        <a routerLink="/dashboard" routerLinkActive="active" class="btn rounded-right mt-3">
            <i class="fa-solid fa-chart-line"></i>
            <span class="sidebar-label">Dashboard</span>

        </a>


        <div *ngIf="checkAdmin==true">
            <div *ngIf="currentUser?.userType==8">
                <a routerLink="/saler/list" routerLinkActive="active">
                    <i class="fa-solid fa-users-line" style="margin-left: 8px;"></i>
                    <span class="sidebar-label">Seller</span>

                </a>
                <a routerLink="/inventory/list" routerLinkActive="active">
                    <span class="sidebar-icon">
                        <i class="fa-solid fa-warehouse"></i>
                    </span>
                    <span class="sidebar-label">Inventory</span>

                </a>
            </div>
        </div>


        <div *ngIf="checkAdmin==true">
            <div *ngIf="currentUser?.userType==7">
                <a routerLink="/receipt/list" routerLinkActive="active">
                    <span class="sidebar-icon">
                        <i class="fa-solid fa-file-invoice"></i>
                    </span>
                    <span class="sidebar-label">Receipt</span>

                </a>
                <a routerLink="/sold/cars/list" routerLinkActive="active">
                    <span class="sidebar-icon">
                        <i class="fa-solid fa-car-side"></i>
                    </span>
                    <span class="sidebar-label">Sold Cars</span>

                </a>
            </div>
        </div>

        <div *ngIf="currentUser?.userType==1">
            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-users"></i>
                    <span class="sidebar-label">Users</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/users/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-users"></i>
                            </span>
                            <span class="sidebar-label">Admin Users</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/buyer/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-users-between-lines"></i>

                            </span>
                            <span class="sidebar-label">Buyer</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Sellers</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/users/credit-history" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Credit History</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/users/querylist" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">User Query List</span>

                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/saler/external-approved-list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-users"></i>
                            </span>Approved Seller
                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/saler-creation" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-right-from-bracket"></i>
                            </span>Seller Approval System
                        </a>
                    </li> -->
                </ul>
            </div>
            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-warehouse"></i>
                    <span class="sidebar-label">Inventory</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/inventory/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </span>
                            <span class="sidebar-label">Inventory</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/inventory/direct-import-cars" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </span>
                            <span class="sidebar-label">Direct Inventory</span>


                        </a>
                    </li>
                    <li>
                        <a routerLink="/master-car/pending" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-hourglass-end"></i>
                            </span>
                            <span class="sidebar-label">Pending Cars</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/master-car/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-hourglass-end"></i>
                            </span>
                            <span class="sidebar-label">Approved Cars</span>

                        </a>
                    </li>
                    <li>
                        <!-- <a routerLink="/gatepass/list" routerLinkActive="active">
                                                <span class="sidebar-icon">
                                                    <i class="fa-solid fa-id-card"></i>
                                                </span>Outgoing Gatepass
                                            </a>
                    
                                            <a routerLink="/gatepass/incoming" routerLinkActive="active">
                                                <span class="sidebar-icon">
                                                    <i class="fa-solid fa-id-card"></i>
                                                </span>Incoming Gatepass
                                            </a> -->


                        <a routerLink="/gatepass/stock-control" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-id-card"></i>
                            </span>
                            <span class="sidebar-label">Stock Control</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/inventory/transfer-list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </span>
                            <span class="sidebar-label">Inventory Transfer</span>

                        </a>
                    </li>

                    <!-- <li>
                        <a routerLink="/inventory/templist" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </span>Inventory Templist
                        </a>
                    </li> -->

                    <!-- <li>
                        <a routerLink="/documents/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-file-lines"></i>
                            </span>Outgoing Documents
                        </a>
                    </li>

                    <li>
                        <a routerLink="/documents/incoming-document-list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-file-lines"></i>
                            </span>Incoming Documents
                        </a>
                    </li> -->






                </ul>
            </div>

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-warehouse"></i>
                    <span class="sidebar-label">Auction</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/auction/stock/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-gavel"></i>
                            </span>
                            <span class="sidebar-label">Auction Stock</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/auction/stock/screening" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-gavel"></i>
                            </span>
                            <span class="sidebar-label">Screening</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/under-approval" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-thumbs-up"></i>
                            </span>
                            <span class="sidebar-label">Under approval</span>

                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/inventory/unsold-cars" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </span>
                            <span class="sidebar-label">Unsold Cars</span>


                        </a>
                    </li> -->
                </ul>
            </div>

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-warehouse"></i>
                    <span class="sidebar-label">Document</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/documents/document-controls" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-file-lines"></i>
                            </span>
                            <span class="sidebar-label">Document Controls</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/documents/document-controls" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-file-lines"></i>
                            </span>
                            <span class="sidebar-label">Document Transfer</span>

                        </a>
                    </li>
                </ul>
            </div>

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Sales</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>

                </a>
                <ul class="dropdown-menu rounded-0">

                    <!-- <li>
                        <a routerLink="/saler/sale-approval" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-thumbs-up"></i>
                            </span>
                            <span class="sidebar-label">Sale approval</span>

                        </a>
                    </li> -->
                    <li>
                        <a routerLink="/receipt/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-file-invoice"></i>
                            </span>
                            <span class="sidebar-label">Receipt</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/sold/cars/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-car-side"></i>
                            </span>
                            <span class="sidebar-label">Sold Cars</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/sold-car-list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-car"></i>
                            </span>
                            <span class="sidebar-label">Sellers Sold Cars</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/seller-sales" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-car"></i>
                            </span>
                            <span class="sidebar-label">Seller Sales Summary</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/seller-service&storage" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-car"></i>
                            </span>
                            <span class="sidebar-label">Service Charge</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/seller-payable" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-car"></i>
                            </span>
                            <span class="sidebar-label">Controll Account</span>

                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/saler/account-payable" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Account Payable</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/account-recieveable" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Account Receiveable</span>

                        </a>
                    </li> -->
                    <li>
                        <a routerLink="/saler/sale-order-history" routerLinkActive="active" *ngIf="checkAdmin==true">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-right-left"></i>
                            </span>
                            <span class="sidebar-label">Deposit</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/receipt/sales-return" routerLinkActive="active" *ngIf="checkAdmin==true">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-right-left"></i>
                            </span>
                            <span class="sidebar-label">Sales Return</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/account/custom-service-invoice" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Custom Service Invoice</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/hayaza-application" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Hayaza Application</span>

                        </a>
                    </li>
                </ul>
            </div>
            <!-- <a routerLink="/sold/cars/other-income" routerLinkActive="active" *ngIf="checkAdmin==true">
                <span class="sidebar-icon">
                    <i class="fa-solid fa-car-side"></i>
                </span>other Income
            </a> -->




            <!-- <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    Document Control
                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>

                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/vin/management/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>documents list
                        </a>
                    </li>

                    <li>
                        <a routerLink="/vin/management/folder-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>Folder List
                        </a>
                    </li>
                </ul>
            </div> -->

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Payment</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/account/incoming-payment-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Incoming Payment List</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/outgoing-payment-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Outgoing Cash Payment List</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/outgoing-payment-syncpay-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Outgoing Other Payment List</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/chequelist" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Cheques</span>

                        </a>
                    </li>
                </ul>
            </div>
            <!-- <a routerLink="/account/expense-list" routerLinkActive="active" class="btn rounded-right">
                <span class="sidebar-icon ">
                    <i class="fa-solid fa-users-line"></i>
                </span>
                <span class="sidebar-label">Expense List</span>

            </a> -->

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Account</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>

                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/account/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Account Master</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/gl-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Account GL List</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/saler/outgoing-payment" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-right-from-bracket"></i>
                            </span>
                            <span class="sidebar-label">Outgoing Payment</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/closing-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Account Closing</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/outgoing-cash/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">SD Approval</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/account/bank-transactions" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Bank Transactions</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/account/security-deposti-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Security Deposit</span>

                        </a>
                    </li>
                </ul>
            </div>


            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Reports</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/reports/auction/profit/analysis" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-hand-holding-dollar"></i>
                            </span>
                            <span class="sidebar-label">Auction Profit Analysis</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/reports/vehicle/aging" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-hand-holding-dollar"></i>
                            </span>
                            <span class="sidebar-label">Vehicle Aging</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/reports/auction/date-wise/profit/analysis" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-hand-holding-dollar"></i>
                            </span>
                            <span class="sidebar-label">Date Wise Sales Analysis</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/reports/bid/report" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-hand-holding-dollar"></i>
                            </span>
                            <span class="sidebar-label">Bid Report</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/reports/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Cash In Hand</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/reports/in-hand" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Cash In hand Summary</span>

                        </a>

                        <a routerLink="/reports/in-bank" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Cash In Bank</span>

                        </a>

                        <a routerLink="/reports/security-amount" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Security Amount</span>

                        </a>


                        <a routerLink="/reports/tex-amount" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Tax Amount</span>
                        </a>

                        <a routerLink="/account/statement" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Statement</span>
                        </a>





                    </li>
                    <li>
                        <a routerLink="/dayend/view" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-hourglass-end"></i>
                            </span>
                            <span class="sidebar-label">Day End</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/reports/hayaza/list" routerLinkActive="active">
                            <span class="sidebar-icon">
                                <i class="fa-solid fa-hourglass-end"></i>
                            </span>
                            <span class="sidebar-label">hayaza list</span>

                        </a>
                    </li>


                </ul>
            </div>



            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Master All</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>

                </a>
                <ul class="dropdown-menu rounded-0">

                    <li>
                        <a routerLink="/master/seller-plan-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Seller Plan List</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/master/make-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Make List</span>

                        </a>
                    </li>
                    <li>
                        <a routerLink="/master/custom-services" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Custom Services</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/master/model-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Model List</span>

                        </a>

                        <!-- <a routerLink="/master/add-branch" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>Add Branch
                        </a> -->

                        <a routerLink="/master/series-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Series List</span>

                        </a>

                        <!-- <a routerLink="/master/year-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>Year List
                        </a> -->

                        <a routerLink="/master/branch-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Branch List</span>

                        </a>

                        <a routerLink="/master/warehouse-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Warehouse List</span>

                        </a>

                        <a routerLink="/master/interior-color-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Interior Color</span>

                        </a>

                        <a routerLink="/master/exterior-color-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Exterior Color</span>

                        </a>

                        <a routerLink="/master/body-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Body List</span>

                        </a>


                        <a routerLink="/master/engine-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Engine List</span>

                        </a>

                        <a routerLink="/master/users-role-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Users Role List</span>

                        </a>


                        <a routerLink="/master/buyer-limit-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Buyer Limit</span>

                        </a>


                        <a routerLink="/master/auction-fee-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Auction Fee</span>

                        </a>

                        <a routerLink="/master/handling-fee-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Handling Fee</span>

                        </a>




                        <a routerLink="/master/transmission-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Transmission List</span>

                        </a>

                        <a routerLink="/master/drive-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Drive Type List</span>

                        </a>

                        <a routerLink="/master/fuel-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Fuel List</span>

                        </a>

                        <a routerLink="/master/term-conditions" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Term and Conditions</span>

                        </a>





                    </li>
                </ul>
            </div>

            <!-- <a routerLink="/incomming-case/list" routerLinkActive="active" *ngIf="checkAdmin==true">
                <span class="sidebar-icon">
                    <i class="fa-solid fa-car-side"></i>
                </span>Incomming Case
            </a> -->

            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Menu</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>

                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/menu/list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Menu List</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/menu/access-add" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Menu Access List</span>

                        </a>
                    </li>
                </ul>
            </div>












            <div class="btn-group" *ngIf="checkAdmin==true">
                <a class="btn rounded-right" data-toggle="dropdown" href="#">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <span class="sidebar-label">Qwaiting</span>

                    <div class="arrow-icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </a>
                <ul class="dropdown-menu rounded-0">
                    <li>
                        <a routerLink="/qwaiting/counter-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Counter List</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/qwaiting/department-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Department List</span>

                        </a>
                    </li>


                    <li>
                        <a routerLink="/qwaiting/mapping-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Counter Mapping List</span>

                        </a>
                    </li>

                    <li>
                        <a routerLink="/qwaiting/token-list" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Token List</span>

                        </a>
                    </li>


                    <li>
                        <a routerLink="/qwaiting/token-queue" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Token Queue</span>

                        </a>
                    </li>


                    <li>
                        <a routerLink="/qwaiting/counter-access" routerLinkActive="active">
                            <span class="sidebar-icon ">
                                <i class="fa-solid fa-users-line"></i>
                            </span>
                            <span class="sidebar-label">Counter Access List</span>

                        </a>
                    </li>





                </ul>
            </div>



            <!-- dynamic sidebar -->
            <div *ngIf="checkAdmin==false">
                <div *ngFor="let menu of allMenu | keyvalue;">
                    <div class="btn-group" *ngIf="menu.value[0]?.parentName">
                        <!-- <a class="btn rounded-right" data-toggle="dropdown" href="#">
                            <i class="fa-solid fa-circle-chevron-down"></i>
                            {{menu.value[0]?.parentName}}

                        </a> -->

                        <a class="btn rounded-right" data-toggle="dropdown" href="#">
                            <i class="fa-solid fa-circle-chevron-down"></i>
                            <span class="sidebar-label">{{menu.value[0]?.parentName}}</span>

                            <div class="arrow-icon">
                                <i class="fa-solid fa-chevron-down"></i>
                            </div>
                        </a>


                        <ul class="dropdown-menu rounded-0">
                            <li *ngFor="let submenu of menu.value">
                                <a routerLink="{{submenu?.menuLink}}" routerLinkActive="active">
                                    <span class="sidebar-icon ">
                                        <!-- <i class="fa-solid fa-users-line"></i> -->
                                    </span>
                                    <span class="sidebar-label">{{submenu?.menuName}}</span>

                                </a>
                            </li>
                        </ul>
                    </div>
                    <a *ngIf="!menu.value[0]?.parentName" routerLink="{{menu?.value?.menuLink}}"
                        routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-right-left"></i>
                        </span>
                        <span class="sidebar-label">{{menu?.value?.menuName}}</span>

                    </a>
                </div>
            </div>


            <!-- end of dynamic sidebar -->
        </div>
    </div>
</div>