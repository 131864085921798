import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Chart } from 'node_modules/chart.js/dist/chart.js';
import { DashboardService } from './../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  graphData: any;
  months: number = 6
  public labels: any = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  public dataCases: any = {
    chart1: [1000, 5000, 15000, 25000, 10000, 20000, 5000, 2000, 7000, 30000],
  };


  constructor(private _header: HeaderService, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    // this.getDashBordData()
    // this.getFeedback()
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  feedbacks = [
    {
      title: 1,
    },
    {
      title: 1,
    },
    {
      title: 1,
    },
  ];

  // Chart
  private createLineChart(labels, dataCases, chartId) {
    this.canvas = document.getElementById(chartId);
    this.ctx = this.canvas.getContext('2d');

    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: '',
            data: dataCases.chart1,
            backgroundColor: '#346EF6',
            borderColor: '#346EF6',
            fill: false,
            borderWidth: 2,
            tension: 0.3,
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false,
            },
          }
        },
        title: {
          display: false,
          text: 'First chart',
        },
        tooltips: {
          mode: 'index',
          intersect: true,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
      },
    });

  }

  clearCanvas(context, canvas) {
    this.chart.destroy();
  }

  selectMonth(month) {
    console.log("*****", month);

    this.clearCanvas(this.ctx, this.canvas)
    this.months = month
    this.getDashBordData()
  }
  getDashBordData() {
    this.dashboardService.getDashboardData({
      "months": this.months
    }).subscribe((res: any) => {
      this.dashboardData = res.result[0];
      this.graphData = res.result[0].graphData
      this.labels = Object.keys(this.graphData)
      this.dataCases = {
        chart1: Object.values(this.graphData),
      };

      this.createLineChart(this.labels, this.dataCases, 'myChart');
    })
  }
}
