<div class="policy">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Privacy Policy</h1>
                <p>Skills Ontario (“we” or “us” or “our”) promotes skills trades and technology in Ontario.  We provide skill development and mentorship opportunities, presentations, regional camps and workshops, and other initiatives aimed at ensuring young people make informed decisions about their future careers.  Skills Ontario also provides an online guidance tool for youths (ages 12-17), using web-based technology that connects users with careers in the skilled trades and technologies, called Skills Ontario App & Resources (S.O.A.R.) (the “App”).<br>
                This Privacy Policy applies to personal information collected through the website and the App, and its related content, information, and services. </p>

                <h1>Our Commitment</h1>
                <p>We are committed to protecting your personal information in accordance with applicable law. This Privacy Policy outlines how we collect, use, disclose and protect your personal information.
                What is Personal Information?</p>
                <p>For the purposes of this Privacy Policy, “personal information” means recorded information about an identifiable individual but does not include business or professional contact information such as name, position, title, work address, work telephone number or work email. </p>

                <h1>Consent to Privacy Policy</h1>
                <p>By using our website or downloading, installing, accessing, copying, or otherwise using the App, or any content, information, function or services available or enabled by us or the App, or completing the App’s account registration process, you acknowledge and consent to the use of personal information in accordance with this Privacy Policy.</p>
                <p><b>What personal information do we collect when you visit our website? </b></p>
                <p>When you visit our website, we collect the following standard types of information: </p>
                <ul>
                    <li> Internet Protocol (IP) addresses of the computers being used to access our site </li>
                    <li>operating systems and the types and versions of browsers used to access our site </li>
                    <li>dates and times users access our sites </li>
                    <li> pages visited</li>
                    <li>names and sizes of files requested </li>
                    <li>Skills Ontario computer systems use hardware and software to monitor unauthorized attempts to upload or change information on our website, disrupt service, or otherwise cause damage. As a general practice, we make no attempt to link this information with the identity of individuals visiting our sites. If an attempt to damage our site has been detected, we can take further actions.</li>
                </ul>

                <p>You may choose to provide us with personal information such as your name, address, phone number, e-mail address and credit card number, if you wish to: </p>
                <ul>
                    <li> fill out an event, volunteer, or alumni association registration form online </li>
                    <li>When you visit Skills Ontario’s website, any personal information you provide will be used to respond to your message and/or provide you with the requested service or product. At the time the information is collected, you will be informed of the specific purpose for which it is being collected. </li>
                </ul>

                <h1>What personal information do we collect when you use our App?</h1>
                <p>When you access or use our App, or register an account on the App, we may collect your email address and other contact information and other information you choose to provide to us for the App. The nature of our App and its content and services requires us to collect personal information about you, which may include your education, skills, interests, and personality, in order for the App to function and provide relevant information, resources and initiatives to you. The personal information collected will be limited to what is required for the App and its content and services. 
                In addition, when you access or use our App, we may automatically collect and store certain personal information about you. This may include your IP address and the date and time you accessed or used our App. </p>

                <h1>How do we collect your personal information?</h1>
                <p>We collect your personal information through our account registration process directly from you. In addition, you may provide us with other information about you through the questions, quizzes, prompts, and response fields contained in the App. 
                </p>

                <h1>Why do we collect, use and disclose personal information?</h1>
                <p>We will only use and disclose personal information for the purposes for which that information was collected, or as otherwise permitted or required by law. The purposes for which we collect, use or disclose personal information include, but are not limited to:</p>

                <ul>
                    <li>roviding the App’s content, information, and services, including:</li>
                    <ul>
                        <li>Assessing your preferences, education, skills, interests, and personality; and</li>
                        <li>Providing you with information, resources and initiatives about careers in the skilled trades and technologies;</li>
                    </ul>
                    <li>Communicating with You; </li>
                    <li>Improving the App; and</li>
                    <li>Any other purposes otherwise permitted or required by law.  </li>
                </ul>

                <h1>How do we protect your personal information?</h1>
                <p>We are committed to protecting your personal information against unauthorized access, collection, use, disclosure, copying, modification or disposal or similar risks. We use reasonable physical and technical security measures to protect personal information, and we continue to develop and enhance our security measures.   We do not sell or trade your personal information.</p>
                <p>While we endeavor to protect all information, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot absolutely guarantee the security of information. We will continually review and update our securities policies, procedures, and measures as technology changes to ensure ongoing personal information security. </p>

                <h1>How long do we keep personal information?</h1>
                <p>We only keep your personal information for the period of time necessary to meet the purposes set out in this Privacy Policy, or as long as otherwise permitted or required by applicable law or contract. </p>

                <h1>Updates, Corrections and Requests for Access</h1>
                <p>We use reasonable efforts to keep your personal information accurate and up-to-date. You are responsible for providing us with updates to your personal information as soon as possible. <br>
                Requests to access, update, correct or delete personal information will be handled as promptly as possible, in accordance with applicable legal requirements. </p>

                <h1>Changes to Privacy Policy</h1>
                <p>This Privacy Policy is subject to change from time to time. Please review this Privacy Policy regularly to stay informed of any changes. By continuing to use the website or the App or its content or services, you acknowledge and consent to any changes to this Privacy Policy. 
                </p>

                <h1>Who can I contact for more information?</h1>
                <p>Questions or comments regarding our practices or access requests may be directed to the Privacy Officer, by email at info@skillsontario.com or in writing to:<br>
                Privacy Officer Skills Ontario<br>
                7A-60 Northland Rd.<br>
                Waterloo, ON<br>
                N2V 2B8</p>
            </div>
        </div>
    </div>
</div>