<div class="content" style="margin-left: 0px !important;">

    <div class="views-header">
        <button class="btn btn-sm btn-light pull-right mr-1" (click)="toggleFullScreen()"
            placeholder="Click to copy code snippet">
            <i class="fas fa-expand"></i>
        </button>

    </div>

    <!-- <tabset type="pills mt-4">
        <tab [heading]="branch?.branchName" (selectTab)="selectedBranch(branch?._id)" *ngFor="let branch of branchList">
        </tab>
    </tabset> -->

    <div class="page-container-header" style="display: none;">
        <h1 class="page-title">Generate Token</h1>
        <div class="breadcrmb"><span class="breadcrumb-link" routerLink="/qwaiting/token-list">
                Document Control</span> <span> <img src="../../../../../assets/icons/breadcrumb-sep.svg" alt="">
                Generate Token
            </span>
        </div>
    </div>

    <div class="page-container-body">
        <div class="card" *ngIf="mainDepartmentSectionCard">
            <div class="card-header">
                <h4 class="card-title">Auto Generate Token</h4>
            </div>
            <div class="card-body" *ngIf="mainDepartmentSection">
                <div class="row mt-3">
                    <div class="col-md-3" (click)="subDepartment(dep?._id,dep?.department)"
                        *ngFor="let dep of departmentList">
                        <div class="token-box">
                            <h4>{{dep?.department}}</h4>
                        </div>
                    </div>
                    <!-- <div class="col-md-3" (click)="subDepartment(2)">
                        <div class="token-box">
                            <h4>Department B</h4>
                        </div>
                    </div>
                    <div class="col-md-3" (click)="subDepartment(3)">
                        <div class="token-box">
                            <h4>Department C</h4>
                        </div>
                    </div>
                    <div class="col-md-3" (click)="subDepartment(4)">
                        <div class="token-box">
                            <h4>Department D</h4>
                        </div>
                    </div>
                    <div class="col-md-3" (click)="subDepartment(5)">
                        <div class="token-box">
                            <h4>Department E</h4>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- Sub category -->
            <div class="card-body" *ngIf="subDepartmentSection">
                <div class="row mt-3">
                    <div class="col-md-12">
                        <h4>
                            <a href="javascript:void(0)" (click)="mainDepartment(1)">
                                <i class="fa fa-undo"></i> Back to Main Department
                            </a>
                        </h4>
                    </div>
                    <div class="col-md-12">
                        <div class="token-box dark-bg">
                            <h4>{{departmentNameShow | titlecase}}</h4>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-3" *ngFor="let child of childDepartmentList">
                        <div class="token-box" (click)="genretedTokenTab(1)">
                            <h4 (click)="generateTokenChild(child?._id)">{{child?.department}}</h4>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="token-box">
                            <h4>Department A Sub</h4>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="token-box">
                            <h4>Department A Sub</h4>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="token-box">
                            <h4>Department A Sub</h4>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>

        <button class="btn btn-red" printTitle="token" id="printToken" printSectionId="print-section"
            style="display: none;" styleSheetFile="assets/scss/token-print-style.scss" ngxPrint><i
                class="fa-solid fa-print"></i>
            Print</button>

        <div class="genretedToken" *ngIf="genretedToken" style="display: none;">
            <div id="print-section" #token>
                <div class="card">
                    <!-- {{departmentNameShow | titlecase}} -->
                    <!-- {{generatedToken?.tokenNo}} -->
                    <!-- {{generatedToken?.createdAt  | date: 'yyyy-MM-dd HH:mm': 'UTC'
}} -->
                    <div class="card-body">
                        <div style="text-align: center;">
                            <img src="../../../assets/aca_images/logo.png" alt=""
                                style="width: 80px;margin-bottom: 11px;">
                            <!-- <h3><span>Token</span> - Queue Management System </h3> -->
                            <h1 id="tokenText" style="text-align: center;"></h1>
                            <h3><span>Department</span> - <span id="departmentText"></span> </h3>
                            <!-- <h3><span>Counter</span> - 1</h3> -->
                            <!-- <h3><span>Officer</span> - {{currentUser.name}}</h3> -->
                            <h3 style="text-align: center;"><span>Date</span> - <span id="dateText"></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <h4>
                <a (click)="clicknewToken()" style="cursor: pointer;">
                    <i class="fa fa-undo"></i> generate new token
                </a>
            </h4>
        </div>


    </div>

    <!-- <div class="page-container-body generate-token-details">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Token Generate Details</h4>
            </div>
            <div class="card-body">
                <div class="col-md-6">
                    <div class="g-box-1">
                        <h3>Current Serving</h3>
                        <div class="token-no-box">
                            <div class="token-no-label">Token Number</div>
                            <div class="token-no">204</div>
                        </div>
                        <h3>Serving TIme</h3>
                        <h2 class="token-no-label">00:45:13</h2>
                        <div class="g-box-footer">
                            <div class="total-token text-center">
                                <h3>Total Serve token</h3>
                                <h2>10</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="g-box-2">
                        <ul>
                            <li>Next</li>
                            <li>Call</li>
                            <li>Recall</li>
                            <li>Transfer</li>
                            <li>Start</li>
                            <li>Close</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="g-box-3">
                        <div class="g-box-header">
                            <h3>Upcoming Details</h3>
                        </div>
                        <div class="upcomming-token-body">
                            <ul>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="u-t-box">
                                        <div class="lbl-1">Zhou</div>
                                        <div class="lbl-2">Ind</div>
                                        <div class="lbl-3">Indfasdf </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="g-box-footer text-center">
                            <button class="btn btn-primary"><i class="fa-solid fa-plus"></i> Add Visiter </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <!-- <div class="skill-tabs justify-content-between align-items-top mt-3">
        <div class="skill-tab-left">
            <div class="table-responsive">
                <table class="table mt-2">
                    <thead>
                        <tr>
                            <th scope="col">Department Name</th>
                            <th scope="col">Parent Department Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let department of tokenList;let i = index">
                            <td>{{department?.department}}</td>
                            <td>{{department?.parentName}}</td>
                            <td>{{department?.createdAt  | date: 'yyyy-MM-dd HH:mm': 'UTC'
}}</td>
                            <td>
                                <button class="btn btn-default"
                                    (click)="generateToken(department?._id)">Generate</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 *ngIf="notFound" style="text-align: center;">
                <img src="../../../../../assets/aca_images/not-found.svg" alt="">
            </h3>
            <pagination [boundaryLinks]="false" [totalItems]="totalCount" [itemsPerPage]='itemsPerPage'
                [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
                nextText="&rsaquo;" *ngIf="!notFound && (totalCount > itemsPerPage)" [maxSize]="5">
            </pagination>
        </div>
    </div> -->
</div>


<!-- delete modal -->
<ng-template #template2>
    <div class="modal-header">
        <div style="margin-left: 168px;">
            <h1>Generate Token</h1>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <!-- <img src="../../../../../assets/images/delete-img.svg" alt=""> -->
        <p>Are you sure you want to generate <br> token for this department?</p>
        <div class="modal-btn">
            <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
            <button class="btn btn-confirm" (click)="confirm()">Confirm</button>
        </div>
    </div>
</ng-template>