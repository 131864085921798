import { Component, NgZone, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BuyerService } from '../buyer/services/buyer.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-user-statement',
  templateUrl: './user-statement.component.html',
  styleUrls: ['./user-statement.component.scss']
})
export class UserStatementComponent implements OnInit {
  users: any;
  notFound: boolean;
  selectedUser: any;
  fromDate: any;
  toDate: any;
  myControl: FormControl = new FormControl('');
  statementList: any;
  userTypeStatus = {
    admin: [1],
    seller: [2, 3],
    buyer: [4, 5],
  }
  subscription: any;
  tab1PanelActive = true;
  tab2PanelActive = false;
  userWalletStatement: any;
  userbusinessStatement: any;

  constructor(
    private buyerService: BuyerService,
    private router: Router,
    private auth: AuthService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.myControl.valueChanges.subscribe((value) => {
      // Your method logic here
      this.filter(value);
    });
    this.fromDate = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.subscription = this.sharedService.id$.pipe(
      distinctUntilChanged()
    ).subscribe(id => {
      this.zone.run(() => {
        if (id) {
          // this.myControl.patchValue(id);
          this.getBuyersLists(1, 100, id);
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  filter(name: string): void {
    if (!name) {
      this.users = []; // Clear the users array when the input is empty
      return;
    }

    if (name.length > 3) {
      const data = {
        "page": 1,
        "pageLimit": 50,
        "search": name,
        "searchUserTypes": ['buyer', 'seller'],
      };

      this.buyerService.buyerList(data).subscribe((res: any) => {

        this.users = res.result;
        this.cd.detectChanges();
      });
    }
  }

  getBuyersLists(page, pageLimit, search) {
    let data = {
      "page": page,
      "pageLimit": pageLimit,
      "search": search,
      "searchUserTypes": ['buyer', 'seller'],
      "status": 1
    }

    if (search == '' || search == null || search == undefined) {
      delete data.search
    }

    this.buyerService.buyerList(data)
      .subscribe((res: any) => {
        this.users = res.result;
        this.myControl.setValue(this.users[0])
        this.selectedUser = res.result[0];
        if ([2, 3].includes(this.selectedUser.userType)) {
          this.getStatement();
        } else {
          this.getBusinessAndWallet();
        }
        if (res.totalCount == 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }
      })
  }

  onSelectedSaller(event: any) {
    this.selectedUser = event?.option?.value;
    if (this.selectedUser) {
      if ([2, 3].includes(this.selectedUser.userType)) {
        this.getStatement();
      } else {
        this.getBusinessAndWallet();
      }
    }
  }

  async getBusinessAndWallet() {
    const buyerfilterWallet = this.buyerFilterSearch(true);
    const statementWallet: any = await this.sharedService.getUserStatement(buyerfilterWallet).toPromise();
    const buyerBusinessParnter = this.buyerFilterSearch(false);
    const statementbusiness: any = await this.sharedService.getUserStatement(buyerBusinessParnter).toPromise();
    this.userbusinessStatement = statementbusiness.result;
    this.userWalletStatement = statementWallet.result;
    if (this.tab1PanelActive) {
      this.statementList = this.userbusinessStatement;
      console.log("===============bsuiness closing===========", this.userbusinessStatement?.closingBalance);
    }
    if (this.tab2PanelActive) {
      this.statementList = this.userWalletStatement;
      console.log("===============wallet closing===========", this.userWalletStatement?.closingBalance);
    }
  }

  getStatement() {
    const filter = this.filterSearch();
    this.sharedService.getUserStatement(filter).subscribe((res: any) => {
      if (res.status) {
        this.statementList = res.result;
      }
    })

  }

  buyerFilterSearch(isWallet) {
    const postData = {
      buyerNo: this.selectedUser.uniqueIdentifier,
      ledgerType: isWallet == true ? ['wallet', "plan-deposit"] : ['receivable'],
      fromDate: this.fromDate,
      toDate: this.toDate,
      transactionOf: 'buyer',
    }
    return postData;
  }

  filterSearch() {
    const entityNo = this.userTypeStatus.buyer.includes(this.selectedUser.userType) ? 'buyerNo' : 'sellerNo';
    const userType = this.userTypeStatus.buyer.includes(this.selectedUser.userType) ? 'buyer' : 'seller';

    const postFilter = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      transactionOf: this.userTypeStatus.buyer.includes(this.selectedUser.userType) ? 'buyer' : 'seller',
      [entityNo]: this.selectedUser.uniqueIdentifier
    }
    if (userType == 'seller') {
      postFilter['ledgerType'] = ['payable-to-seller', 'payable-to-jabal', 'payable-to-shams', 'payable-to-rafah'];
    }
    if (userType == 'buyer') {
      postFilter['ledgerType'] = this.tab2PanelActive == true ? ['wallet', "plan-deposit"] : ['receivable'];
    }
    return postFilter
  }


  displayFn(user: any): string {
    return user ? user.name + '(' + user.phone + ')' : '';
  }

  searchTransaction() {
    this.statementList = [];
    if (this.selectedUser) {
      if ([2, 3].includes(this.selectedUser.userType)) {
        this.getStatement();
      } else {
        this.getBusinessAndWallet();
      }
    }
  }

  clearFilter() {
    this.fromDate = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.myControl.patchValue('');
    this.statementList = [];
    if (this.selectedUser) {
      if ([2, 3].includes(this.selectedUser.userType)) {
        this.getStatement();
      } else {
        this.getBusinessAndWallet();
      }
    }

  }

  tab1Nav() {
    this.tab2PanelActive = false;
    this.tab1PanelActive = true;
    this.statementList = [];
    this.getStatement();
  }

  tab2Nav() {
    this.tab2PanelActive = true;
    this.tab1PanelActive = false;
    this.statementList = [];
    this.getStatement();
  }

}
