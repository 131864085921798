import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class BidStockService {
  url: any = environment.API_URL;
  auctionId: any;
  inventoryId: any;
  public data$: BehaviorSubject<string> = new BehaviorSubject('');
  public latestBidData$: BehaviorSubject<string> = new BehaviorSubject('');
  public activeUser$: BehaviorSubject<string> = new BehaviorSubject('');
  public messageData$: BehaviorSubject<string> = new BehaviorSubject('');
  public messageBidData$: BehaviorSubject<string> = new BehaviorSubject('');
  public timerDuration$: BehaviorSubject<string> = new BehaviorSubject('');
  public onlineBidLast$: BehaviorSubject<string> = new BehaviorSubject('');
  public highLightFlag$: BehaviorSubject<string> = new BehaviorSubject('');
  public skipStatus$: BehaviorSubject<string> = new BehaviorSubject('');
  public inventryData$: BehaviorSubject<string> = new BehaviorSubject('');
  public isCarApprovedData$: BehaviorSubject<string> = new BehaviorSubject('');
  // public reservePriceData$: BehaviorSubject<string> = new BehaviorSubject(null);

  public isBidRemainContinue$: ReplaySubject<string> = new ReplaySubject(1)
  public reservePriceData$: ReplaySubject<string> = new ReplaySubject(1)
  public isProcessingData$: BehaviorSubject<string> = new BehaviorSubject('');
  public isAuctionPaused$: BehaviorSubject<string> = new BehaviorSubject('');
  public isAuctionVehicleReload$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private http: HttpClient, private socket: Socket) { }

  joinAuction(auctionId: any, inventoryId: any) {
    //console.log('2===>', auctionId, inventoryId);
    this.socket.connect();

    // Send the auction ID to the server to join the corresponding room
    this.socket.emit('joinAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
    this.auctionId = auctionId;
    this.inventoryId = inventoryId;
  }

  // getCount(auctionId: any, inventoryId: any) {
  //   this.socket.('yourRoom')
  //     .allSockets()
  //     .then((result) => {
  //       console.log(res.size);
  //     });
  // }

  addBid(data: Object) {
    this.socket.connect();
    this.socket.emit('bidData', data);
  }

  public getUserCounts = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('userCount', (data: any) => {
      this.activeUser$.next(data);
    });
    return this.activeUser$.asObservable();
  };

  public isCarApproved = () => {
    this.socket.connect();
    this.socket.on('isProcessDone', (data: any) => {
      this.isCarApprovedData$.next(data);
    });
    return this.isCarApprovedData$.asObservable();
  };
  public getReservePrice = () => {
    this.socket.connect();
    this.socket.on('getReservePrice', (data: any) => {
      this.reservePriceData$.next(data);
    });
    return this.reservePriceData$.asObservable();
  };

  public isProcessing = () => {
    this.socket.connect();
    this.socket.on('isProcessing', (data: any) => {
      this.isProcessingData$.next(data);
    });
    return this.isProcessingData$.asObservable();
  };

  public userRefreshRequest = (data) => {
    this.socket.emit('userRefreshed', data);
  };


  public pauseAuction = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('pause', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public auctionVehicleReload = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('inventoryListRefresh', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  public isAuctionPaused = () => {
    this.socket.connect();
    this.socket.on('pauseAuction', (data: any) => {
      this.isAuctionPaused$.next(data);
    });
    return this.isAuctionPaused$.asObservable();
  };

  public getSkipStatus = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('skipStatus', (data: any) => {
      this.skipStatus$.next(data);
    });
    return this.skipStatus$.asObservable();
  };

  public getOnlineBidTime = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('onlineBidSartTime', (data: any) => {
      this.onlineBidLast$.next(data);
    });
    return this.onlineBidLast$.asObservable();
  };

  public getShowHighLightFlag = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('highlightEvent', (data: any) => {
      this.highLightFlag$.next(data);
    });
    return this.highLightFlag$.asObservable();
  };

  public getOnlimeTimerDuration = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('onlieTimerDuration', (data: any) => {
      //console.log("============>",data)
      //if (data.auctionId == auctionId) {
      this.timerDuration$.next(data);
      // }
    });
    return this.timerDuration$.asObservable();
  };

  public getCurrentBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('data', (data: any) => {
      this.data$.next(data);
    });
    return this.data$.asObservable();
  };

  public callBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('getBidData', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public callUpdateReserve = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('updatReservePrice', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public closeBids = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('closeAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status
    });
  };

  public showHighlight = (auctionId: any, inventoryId: any, flag) => {
    this.socket.connect();
    this.socket.emit('showHighlight', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      flag: flag,
    });
  };

  public underApproval = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('unApprove', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public approveBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('approveBids', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public skipAuction = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('skipAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public removeBids = (auctionId: any, inventoryId: any, count: any) => {
    this.socket.connect();
    this.socket.emit('removeBids', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      count: count,
    });
  };

  public puseBids = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('puaseAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public auctionStart = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('auctionStart', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public getInventoryData = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('inventryData', (data: any) => {
      this.inventryData$.next(data);
    });
    return this.inventryData$.asObservable();
  };

  public getBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('getData', (data: any) => {
      //if (data.auctionId == auctionId) {
      this.latestBidData$.next(data);
      // }
    });
    return this.latestBidData$.asObservable();
  };

  // bidHistory() {
  //   return Observable.create((observer) => {
  //     this.socket.on('bidHistory', (message: any) => {
  //       // console.log('output message', message);
  //       observer.next(message);
  //     });
  //   });
  // }

  public bidRemainContinue = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('continueBid', (data: any) => {
      this.isBidRemainContinue$.next(data);
    });
    return this.isBidRemainContinue$.asObservable();
  };

  public getMessage = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('bidAdminMessage', (data: any) => {
      //if (data.auctionId == auctionId) {
      this.messageData$.next(data);
      // }
    });
    return this.messageData$.asObservable();
  };

  public getBidMessage = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('bidMessage', (data: any) => {
      //if (data.auctionId == auctionId) {
      this.messageBidData$.next(data);
      // }
    });
    return this.messageBidData$.asObservable();
  };

  socketon() {
    this.socket.connect();
    console.log('this.socket', this.socket);
  }

  socketend() {
    this.socket.disconnect();
  }
}
