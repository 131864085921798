import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RequestOptions } from 'https';
import { Observable } from 'rxjs';
@Injectable()
export class SalerService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient) { }

  registerSaler(data: any) {
    return this.http.post(this.url + 'user/register', data);
  }

  SalerList(data: any) {
    return this.http.post(this.url + 'user/list', data);
  }

  orderHistoryList(data: any) {
    return this.http.post(this.url + 'order/list', data);
  }

  createOrderWithDrawRequest(data: any) {
    return this.http.post(this.url + 'order/create', data)
  }
  getReceiptwithReceiptNo(data: any) {
    return this.http.post(this.url + 'reciept/get-receipt-by-receiptNo', data)
  }

  transferDepositToWallet(data: any) {
    return this.http.post(this.url + 'reciept/transfer-deposit-to-wallet', data)
  }

  updateStatusOfOrderDepost(data: any) {
    return this.http.patch(this.url + 'order/update-order', data);
  }
  approveOrder(data: any) {
    return this.http.post(this.url + 'order/approve', data);
  }

  updateSaler(data: any) {
    return this.http.patch(this.url + 'user/update', data);
  }
  soldtList(data: any) {
    return this.http.post(this.url + 'salereciept/list', data);
  }
  serviceChargeList(data: any) {
    return this.http.post(this.url + 'salereciept/servicechargelist', data);
  }
  counterOfferFromAdmin(data: any) {
    return this.http.post(this.url + 'bid/update-bid', data);
  }
  auctionDetail(data: any) {
    return this.http.post(this.url + 'auction/get-auction', data);
  }
  getPrebid(data: any) {
    return this.http.post(this.url + 'bid/get-bid', data);
  }
  serviceChargeUpdate(data: any) {
    return this.http.post(this.url + 'salereciept/servicechargeupdate', data);
  }
  getSellerPayableList(data: any) {
    return this.http.post(this.url + 'salereciept/getsellerpayablelist', data);
  }
  addSellerPayableList(data: any) {
    return this.http.post(this.url + 'salereciept/addpaybale', data);
  }

  addStorage(data: any) {
    return this.http.patch(this.url + 'account/add-payment-storage', data);
  }

  sellerPayableList(data: any) {
    return this.http.post(this.url + 'salereciept/sellerpaybalelist', data);
  }

  chequeList(data: any) {
    return this.http.post(this.url + 'salereciept/get-seller-cheque', data);
  }

  chequeStatusUpdate(data: any) {
    return this.http.patch(this.url + 'salereciept/sellerpaybaleupdate', data);
  }

  sellerPayableListHistory(data: any) {
    return this.http.post(
      this.url + 'salereciept/seller-payable-history',
      data
    );
  }
  sellerPayableDelete(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}),
      body: data,
    };
    return this.http.delete(this.url + 'salereciept/delete', httpOptions);
  }
  updateSellerPaybale(data: any) {
    return this.http.patch(this.url + 'salereciept/sellerpaybaleupdate', data);
  }
  updatePayment(data: any) {
    return this.http.patch(this.url + 'salereciept/update', data);
  }
  importInventory(data: any) {
    let header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/json');
    return this.http.post(this.url + 'inventory/import', data, {
      headers: header,
    });
  }

  approveReject(data: any) {
    return this.http.patch(this.url + 'user/update', data);
  }

  checkAccess(data: any) {
    return this.http.post(this.url + 'master/sidebarMenuAcess', data);
  }

  branchList(data: any) {
    return this.http.post(this.url + 'master/branchlist', data);
  }

  warehouseList(data: any) {
    return this.http.post(this.url + 'master/warehouselist', data);
  }

  changePassword(data: any) {
    return this.http.patch(this.url + 'user/changePasswordByAdmin', data);
  }

  receiptList(data: any) {
    return this.http.post(this.url + 'reciept/list', data);
  }

  auctionVehicleList(data: any) {
    return this.http.post(this.url + 'auction/auctionvehicleslist', data);
  }

  getCommentList(data: any) {
    return this.http.post(this.url + 'user/getAllMessage', data);
  }

  getCombinedCommentList(data: any) {
    return this.http.post(this.url + 'comment/get-combined', data);
  }

  getPlanDetails(data: any) {
    return this.http.post(this.url + 'master/sellerplanlist', data);
  }

  sellerPlanList(data: any) {
    return this.http.post(this.url + 'master/sellerplanlist', data);
  }

  updateSellerPlan(data: any) {
    return this.http.patch(this.url + 'inventory/updatesellerplan', data);
  }

  sellerPlanMappingList(data: any) {
    return this.http.post(this.url + 'inventory/sellermappingbranchList', data);
  }

  addSellerPlanMapping(data: any) {
    return this.http.post(this.url + 'inventory/addsellermappingbranch', data);
  }

  sellerInvoiceData(data: any) {
    return this.http.post(this.url + 'salereciept/get-invoice', data);
  }

  accountGlListReport(data: any) {
    return this.http.post(this.url + 'account/glListReport', data);
  }


  receiptUpdate(id, data) {
    return this.http.patch(this.url + `reciept/update/${id}`, data);
  }

  exportReport() {
    return this.http.post(this.url + `reciept/download-sale-report-csv`, {});
  }

  downloadFile(requestData: any): Observable<any> {
    return this.http.post(this.url + `reciept/download-sale-report-csv`, requestData, { responseType: 'blob' });
  }

  getSellerSales(data: any) {
    return this.http.post(this.url + 'salereciept/seller-sales', data);
  }

  getLedgerDescription(data: any) {
    return this.http.post(this.url + 'account/ledger-sales-records', data);
  }

  sellerAnalytics(data: any) {
    return this.http.post(this.url + 'analytics/seller-dashboard-analytics', data);
  }

  getHayazaApplication(data: any) {
    return this.http.get(this.url + `outsource/hayaza-application?${data}`);
  }

  createHayazaApplication(data: any) {
    return this.http.post(this.url + `outsource/hayaza-application`, data);
  }

  UpdateHayazaApplication(data: any, id: any) {
    return this.http.patch(this.url + `outsource/hayaza-application/${id}`, data);
  }

}
