import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class ReportsService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }


    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    addAccount(data: any) {
        return this.http.post(this.url + 'reciept/bankTransfer', data);
    }

    dateWiseProfitAnalysis(data: any) {
        return this.http.post(this.url + 'auction/collective-profit-report-analysis', data);
    }

    getBidPlanReport(data: any) {
        return this.http.post(this.url + 'order/users-plan-report', data);
    }

    getBidWalletReport(data: any) {
        return this.http.post(this.url + 'order/users-wallet-report', data);
    }

    inHandReport(data: any) {
        return this.http.post(this.url + 'reciept/cashSummary', data);
    }

    getReportList(data: any) {
        return this.http.post(this.url + 'reciept/cashinhand', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    accountGlListReport(data: any) {
        return this.http.post(this.url + 'account/glListReport', data);
    }

    summary(data: any) {
        return this.http.post(this.url + 'account/cashInHandSummary', data);
    }

}