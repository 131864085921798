import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class MasterAllService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }


    addMake(data: any) {
        return this.http.post(this.url + 'master/add', data);
    }

    updateMake(data: any) {
        return this.http.patch(this.url + 'master/update', data);
    }

    makeList(data: any) {
        return this.http.post(this.url + 'master/list', data);
    }

    addModel(data: any) {
        return this.http.post(this.url + 'master/modeladd', data);
    }

    addTermsCondition(data: any) {
        return this.http.post(this.url + 'termscondition/add-terms-condition', data);
    }

    getTermsCondition(data: any) {
        return this.http.post(this.url + 'termscondition/get-terms-condition', data);
    }

    updateModel(data: any) {
        return this.http.patch(this.url + 'master/modelupdate', data);
    }

    modelList(data: any) {
        return this.http.post(this.url + 'master/modellist', data);
    }

    addSeries(data: any) {
        return this.http.post(this.url + 'master/seriesadd', data);
    }

    updateSeries(data: any) {
        return this.http.patch(this.url + 'master/seriesupdate', data);
    }

    seriesList(data: any) {
        return this.http.post(this.url + 'master/serieslist', data);
    }

    addYear(data: any) {
        return this.http.post(this.url + 'master/yearadd', data);
    }

    updateYear(data: any) {
        return this.http.patch(this.url + 'master/yearupdate', data);
    }

    yearList(data: any) {
        return this.http.post(this.url + 'master/yearlist', data);
    }


    addBranch(data: any) {
        return this.http.post(this.url + 'master/branchadd', data);
    }

    updateBranch(data: any) {
        return this.http.patch(this.url + 'master/branchupdate', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }

    addWarehouse(data: any) {
        return this.http.post(this.url + 'master/warehouseadd', data);
    }

    updateWarehouse(data: any) {
        return this.http.patch(this.url + 'master/warehouseupdate', data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }


    addBody(data: any) {
        return this.http.post(this.url + 'master/bodyadd', data);
    }

    updateBody(data: any) {
        return this.http.patch(this.url + 'master/bodyupdate', data);
    }

    bodyList(data: any) {
        return this.http.post(this.url + 'master/bodylist', data);
    }

    addEngine(data: any) {
        return this.http.post(this.url + 'master/engineadd', data);
    }

    updateEngine(data: any) {
        return this.http.patch(this.url + 'master/engineupdate', data);
    }

    engineList(data: any) {
        return this.http.post(this.url + 'master/enginelist', data);
    }

    addUserRole(data: any) {
        return this.http.post(this.url + 'master/addUserRole', data);
    }

    updateUserRole(data: any) {
        return this.http.patch(this.url + 'master/updateUserRole', data);
    }

    userRoleList(data: any) {
        return this.http.post(this.url + 'master/userRoleList', data);
    }

    addInteriorColor(data: any) {
        return this.http.post(this.url + 'master/interiorcoloradd', data);
    }

    updateInteriorColor(data: any) {
        return this.http.patch(this.url + 'master/interiorcolorupdate', data);
    }

    InteriorColorList(data: any) {
        return this.http.post(this.url + 'master/interiorcolorlist', data);
    }

    addExteriorColor(data: any) {
        return this.http.post(this.url + 'master/exteriorcoloradd', data);
    }

    updateExteriorColor(data: any) {
        return this.http.patch(this.url + 'master/exteriorcolorupdate', data);
    }

    exteriorColorList(data: any) {
        return this.http.post(this.url + 'master/exteriorcolorlist', data);
    }

    addBuyerLimit(data: any) {
        return this.http.post(this.url + 'master/buyerlimitadd', data);
    }

    updateBuyerLimit(data: any) {
        return this.http.patch(this.url + 'master/buyerlimitupdate', data);
    }

    buyerLimitList(data: any) {
        return this.http.post(this.url + 'master/buyerlimitlist', data);
    }

    addAuctionFee(data: any) {
        return this.http.post(this.url + 'master/auctionfeeadd', data);
    }

    updateAuctionFee(data: any) {
        return this.http.patch(this.url + 'master/auctionfeeupdate', data);
    }

    auctionFeeList(data: any) {
        return this.http.post(this.url + 'master/auctionfeelist', data);
    }



    addMenu(data: any) {
        return this.http.post(this.url + 'master/menuAdd', data);
    }

    updateMenu(data: any) {
        return this.http.patch(this.url + 'master/menuUpdate', data);
    }

    menuList(data: any) {
        return this.http.post(this.url + 'master/menuList', data);
    }

    addTrans(data: any) {
        return this.http.post(this.url + 'master/transmissionadd', data);
    }

    updateTrans(data: any) {
        return this.http.patch(this.url + 'master/transmissionupdate', data);
    }

    transList(data: any) {
        return this.http.post(this.url + 'master/transmissionlist', data);
    }

    addDrive(data: any) {
        return this.http.post(this.url + 'master/drivetypeadd', data);
    }

    updateDrive(data: any) {
        return this.http.patch(this.url + 'master/drivetypeupdate', data);
    }

    driveList(data: any) {
        return this.http.post(this.url + 'master/drivetypelist', data);
    }

    addFuel(data: any) {
        return this.http.post(this.url + 'master/fueltypeadd', data);
    }

    updateFuel(data: any) {
        return this.http.patch(this.url + 'master/fueltypeupdate', data);
    }

    fuelList(data: any) {
        return this.http.post(this.url + 'master/fueltypelist', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    addHandlingFee(data: any) {
        return this.http.post(this.url + 'master/handlingfeeadd', data);
    }

    updateHandlingFee(data: any) {
        return this.http.patch(this.url + 'master/handlingfeeupdate', data);
    }

    handlingFeeList(data: any) {
        return this.http.post(this.url + 'master/handlingfeelist', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    addSellerPlan(data: any) {
        return this.http.post(this.url + 'master/sellerplanadd', data);
    }

    updateSellerPlan(data: any) {
        return this.http.patch(this.url + 'master/sellerplanupdate', data);
    }

    sellerPlanList(data: any) {
        return this.http.post(this.url + 'master/sellerplanlist', data);
    }

    /* Custom Services server Calls*/

    addCustomServices(data) {
        return this.http.post(this.url + 'master/custom-services', data);
    }
    getCustomServices() {
        return this.http.get(this.url + 'master/custom-services');
    }
    updateCustomServices(id, data) {
        return this.http.patch(this.url + `master/custom-services/${id}`, data);
    }
    deleteCustomServices(id) {
        return this.http.delete(this.url + `master/custom-services/${id}`);
    }

    createCustomServiceInvoice(data: any) {
        return this.http.post(this.url + 'custom/invoice', data);
    }

    getCustomServiceInvoice(data) {
        return this.http.get(this.url + `custom/invoice?${data}`);
    }

    getCustomServiceInvoiceDocData(id) {
        return this.http.get(this.url + `custom/invoice/docs/${id}`);
    }

    approveCustomServiceInvoice(id, data) {
        return this.http.patch(this.url + 'custom/invoice/' + id, data);
    }


}