<app-layout></app-layout>

<div class="content">
    <div class="views-header">
        <div class="breadcrmb"> <span class="breadcrumb-link"> Edit Profile</span></div>
        <div>
            <button class="btn btn-blue" (click)="editProfile()">Save</button>
        </div>
    </div>

    <div class="edit-profile">
        <div class="row">
            <div class="col-md-4">
                <form [formGroup]="profileForm">
                    <div class="form-group">
                        <div class="user-img text-center">
                            <div class="file-input-wrapper">
                                <img [src]="imgURL" alt="" height="116px" width="116px">
                                <img class="img-overlay" src="../../../../assets/images/edit-overlay.svg" alt=""
                                    height="116px" width="116px">
                                <input #myFile2 type="file" name="myFile2" id="myFile2" value="" multiple=""
                                    (change)="onFileChange($event)" accept="image/*" />
                                <input type="hidden" name="" id="" formControlName="myFile">
                            </div>
                            <div *ngIf="submitted && f.myFile.errors" class="alert alert-danger">
                                <span *ngIf="lang == 'eng'">Image is required</span>
                                <span *ngIf="(lang == 'fre')">Image est requise.</span>
                            </div>
                        </div>

                    </div>

                    <div class="form-group">
                        <label for="">First Name <span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput value="" formControlName="fname">
                            <div class="alert alert-danger" *ngIf="submitted && f.fname.errors">
                                <span *ngIf="lang == 'eng'">First name is required.</span>
                                <span *ngIf="lang == 'fre'">Prénom est requis.</span>
                            </div>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <label for="">Last Name <span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput value="" formControlName="lname">
                            <div class="alert alert-danger" *ngIf="submitted && f.lname.errors">
                                <span *ngIf="lang == 'eng'">Last name is required.</span>
                                <span *ngIf="lang == 'fre'">Nom de famille est requis.</span>
                            </div>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <label for="">Email Address <span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput value="" formControlName="email">
                            <div class="alert alert-danger" *ngIf="submitted && f.email.errors">
                                <span *ngIf="f.email.errors.required && (lang == 'eng')">
                                    Email is required.
                                </span>
                                <span *ngIf="f.email.errors.required && (lang == 'fre')">
                                    Courriel est requis.
                                </span>
                                <span *ngIf="f.email.errors.email && (lang == 'eng')">
                                    Invalid email.
                                </span>
                                <span *ngIf="f.email.errors.email && (lang == 'fre')">
                                    Courriel invalide.
                                </span>
                            </div>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <form [formGroup]="passwordForm">
                    <div class="change-pass">
                        <h3>Change Password</h3>
                        <div class="form-group">
                            <label for="">Old Password <span class="error-asterisk">*</span></label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide ? 'password' : 'text'" value=""
                                    formControlName="oldPassword">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.oldPassword.errors">
                                    <span *ngIf="p.oldPassword.errors.required && (lang == 'eng')">
                                        Old password is required.
                                    </span>
                                    <span *ngIf="p.oldPassword.errors.required && (lang == 'fre')">
                                        Ancien mot de passe est requis.
                                    </span>
                                    <span *ngIf="p.oldPassword.errors.minlength && (lang == 'eng')">
                                        Password must have at least 6 characters.
                                    </span>
                                    <span *ngIf="p.oldPassword.errors.minlength && (lang == 'fre')">
                                        Mot de passe doit contenir au moins 6 caractères.
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label for="">New Password <span class="error-asterisk">*</span></label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide1 ? 'password' : 'text'" value=""
                                    formControlName="newPassword">
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.newPassword.errors">
                                    <span *ngIf="p.newPassword.errors.required && (lang =='eng')">
                                        New password is required.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.required && (lang =='fre')">
                                        Nouveau mot de passe est requis.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.minlength && (lang == 'eng')">
                                        Password must have at least 6 characters.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.minlength && (lang == 'fre')">
                                        Mot de passe doit contenir au moins 6 caractères.
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label for="">Confirm Password <span class="error-asterisk">*</span></label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide2 ? 'password' : 'text'" value=""
                                    formControlName="confirmPassword">
                                <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.confirmPassword.errors">
                                    <span *ngIf="p.confirmPassword.errors.required && (lang == 'eng')">
                                        Confirm password is required.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.required && (lang == 'fre')">
                                        Confirmer le mot de passe est requis.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.minlength && (lang == 'eng')">
                                        Password must have at least 6 characters.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.minlength && (lang == 'fre')">
                                        Le mot de passe doit avoir au moins 6 caractères.
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-blue" (click)="changePassword()">Change Password</button>
                            <p class="pass-change-msg" *ngIf="passChangeMsg">Password Changed Successfully!</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </form> -->

<ng-template #template2>
    <image-cropper [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
        [resizeToWidth]="256" format="png" (imageCropped)="cropImg($event)">
    </image-cropper>

    <button type="button" class="btn btn-danger static smallest" data-dismiss="modal"
        (click)="uploadImage()">OK</button>
    <!--<div class="col-md-4">
  <h6>Image Preview</h6>
  <img [src]="cropImgPreview" />
</div>-->
</ng-template>