import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomFormsModule } from 'ng2-validation';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { AuthService } from './auth/services/auth.service';
import { SharedService } from './shared/services/shared.service';
import { UserService } from './views/users/services/user.service';
import { PartnerService } from './views/partners/partner.service';
import { DashboardService } from './dashboard/services/dashboard.service';
import { QuizService } from './views/quiz/services/quiz.service';
import { NotificationService } from './views/notifications/notification.service';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SessionService } from './shared/services/session.service';
import { PolicyComponent } from './views/policy/policy.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InventoryService } from './views/inventory/services/inventory.service';
import { AuctionStockService } from './views/auction-stock/services/auction-stock.service';
import { SalerService } from './views/saler/services/saler.service';
import { BuyerService } from './views/buyer/services/buyer.service';
import { SoldCarsService } from './views/sold-cars/services/sold-cars.service';
import { ReceiptService } from './views/receipt/services/receipt.service';
import { WebcamModule } from 'ngx-webcam';
import { DocumentsService } from './views/documents/services/documents.service';
import { GatepassService } from './views/gatepass/services/gatepass.service';
import { VinManagementService } from './views/vin-management/services/vin-management.service';
import { MasterCarService } from './views/master-car/services/master-car.service';
import { ReportsService } from './views/reports/services/reports.service';
import { BidService } from './views/bid/services/bid.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { MasterAllService } from './views/master-all/services/master-all.service';
import { IncommingCaseService } from './views/incomming-case/services/incomming-case.service';
import { MenuService } from './views/menu/services/menu.service';
import { AccountService } from './views/account/services/account.service';
import { FolderStructureService } from './views/folder-structure/services/folder-structure.service';
import { QwaitingService } from './views/qwaiting/services/qwaiting.service';
import { OutgoingCashService } from './views/outgoing-cash/services/outgoing.service';
import { TokenSystemComponent } from './views/token-system/token-system.component';
import { GenerateTokenComponent } from './views/generate-token/generate-token.component';
import { NgxPrintModule } from 'ngx-print';
import { BidStockService } from './views/bid/services/bid-stock.service ';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { UserStatementComponent } from './views/user-statement/user-statement.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
const config: SocketIoConfig = { url: environment.SOCKET_URL, options: {} };
// import { NgxPrintModule } from 'ngx-print';
// ngx-bootstrap

@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent,
    PolicyComponent,
    TokenSystemComponent,
    GenerateTokenComponent,
    UserStatementComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ImageCropperModule,
    BsDropdownModule,
    NgxIntlTelInputModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    MatSelectModule,
    MatSlideToggleModule,
    SocketIoModule.forRoot(config),
    NgxPrintModule,
    TabsModule.forRoot()
  ],
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    UserService,
    PartnerService,
    DashboardService,
    QuizService,
    NotificationService,
    SessionService,
    InventoryService,
    AuctionStockService,
    SalerService,
    BuyerService,
    SoldCarsService,
    ReceiptService,
    WebcamModule,
    DocumentsService,
    GatepassService,
    VinManagementService,
    MasterCarService,
    ReportsService,
    BidService,
    BidStockService,
    MasterAllService,
    // NgxPrintModule,
    IncommingCaseService,
    MenuService,
    AccountService,
    FolderStructureService,
    QwaitingService,
    OutgoingCashService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
