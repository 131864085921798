import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class AccountService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    accountList(data: any) {
        return this.http.post(this.url + 'account/list', data);
    }

    addAccount(data: any) {
        return this.http.post(this.url + 'account/add', data);
    }

    getAccountPayable(data: any) {
        return this.http.post(this.url + 'account/accountpayablelist', data);
    }

    updateAccount(data: any) {
        return this.http.patch(this.url + 'account/update', data);
    }

    getSecurityAmountList(data: any) {
        return this.http.post(this.url + 'reciept/security-amount-list', data);
    }

    deleteAccount(data: any) {

        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'account/delete', httpOptions);
    }


    accountGlList(data: any) {
        return this.http.post(this.url + 'account/glList', data);
    }


    accountGlTempList(data: any) {
        return this.http.post(this.url + 'account/gltempList', data);
    }

    addGlAccount(data: any) {
        return this.http.post(this.url + 'account/addGl', data);
    }


    addGlTemp(data: any) {
        return this.http.post(this.url + 'account/addtempGl', data);
    }



    accountGlListReport(data: any) {
        return this.http.post(this.url + 'account/glListReport', data);
    }

    reportStatementList(data: any) {
        return this.http.post(this.url + 'account/statement', data);
    }


    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }

    branchList(data: any) {
        return this.http.post(this.url + 'master/branchlist', data);
    }


    receiptList(data: any) {
        return this.http.post(this.url + 'reciept/list', data);
    }

    salesReturnList(data: any) {
        return this.http.post(this.url + 'reciept/salesReturnlist', data)
    }


    closingList(data: any) {
        return this.http.post(this.url + 'account/closingDataList', data);
    }

    addClosingAmonut(data: any) {
        return this.http.post(this.url + 'account/closingAccountadd', data);
    }

    dailyClosingAmonut(data: any) {
        return this.http.post(this.url + 'account/dailyBasisclosing', data);
    }

    noteDetails(data: any) {
        return this.http.post(this.url + 'account/closingnotesdetaillist', data)
    }

    addExpense(data: any) {
        return this.http.post(this.url + 'account/expenseadd', data)
    }

    expenseList(data: any) {
        return this.http.post(this.url + 'account/expenselist', data)
    }

    deleteExpense(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'account/expenseitemdelete', httpOptions);
    }


    expenseUpdate(data: any) {
        return this.http.patch(this.url + 'account/expenseupdate', data)
    }


    updateTempGl(data: any) {
        return this.http.patch(this.url + 'account/updatetempGl', data)
    }

    updateGl(data: any) {
        return this.http.patch(this.url + 'account/updateGl', data)
    }

    getClosingBalance(data: any) {
        return this.http.post(this.url + 'account/getclosingBalance', data);
    }




}