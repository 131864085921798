import { HttpClient } from "@angular/common/http";
import { ThrowStmt } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class IncommingCaseService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }


    incommingList(data) {
        return this.http.post(this.url + 'reciept/incomingcashlist', data);
    }

    addIncomming(data) {
        return this.http.post(this.url + 'reciept/incomingcashadd', data);
    }

    searchIncomming(data) {
        return this.http.post(this.url + 'reciept/incomingcashadd', data);
    }

    checkAccess(data: any) {
        return this.http.post(this.url + 'master/sidebarMenuAcess', data);
    }



}