import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class BidService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient, private socket: Socket) { }
  joinAuction(userId: any, auctionId: any) {
    console.log(userId + '  ' + auctionId);

    this.socket.emit('joinAuction', { userId: userId, auctionId: auctionId });
    // let data = this.getMessage();
    // this.bidHistory();
    // this.getTotalOnline();
  }
  addBid(amount: any, userId: any, auctionId) {
    this.socket.emit('bidAction', {
      userId: userId,
      auctionId: auctionId,
      bidAmount: amount,
      type: 'bidNow',
    });
  }

  bidHistory() {
    return Observable.create((observer) => {
      this.socket.on('bidHistory', (message: any) => {
        // console.log('output message', message);
        observer.next(message);
      });
    });
  }

  // public getNewMessage = () => {
  //     this.socket.on('message', (message) => {
  //         this.message$.next(message);
  //     });

  //     return this.message$.asObservable();
  // };

  // getChatHistory() {
  //     return Observable.create((observer) => {
  //         this.socket.on('output', (message) => {
  //             console.log('output message', message);
  //             observer.next(message);
  //         });
  //     });
  // }

  // sendMessage(msg: string) {
  //     this.socket.emit('message', msg);
  // }
  // getMessage() {
  //     return this.socket.fromEvent('message').pipe(map((data) => data));
  // }

  // getTotalOnline() {
  //     return this.socket.fromEvent('joinAuction').pipe(map((data) => data))
  // }
  // uploadImage(file: any) {
  //     return this.http.post(this.url + "upload/image", file);
  // }

  // addVin(data: any) {
  //     return this.http.post(this.url + 'vin/add', data);
  // }

  // getVinList(data: any) {
  //     return this.http.post(this.url + 'vin/list', data);
  // }

  // // answers
  // getAnswer(param) {
  //     console.log('chat service params', this.socket);
  //     this.socket.emit('answerOfChat', param);
  // }

  // initiatChat(param) {
  //     this.socket.emit('initialFromFrontEnd', param);
  // }

  // sendMsg(param) {
  //     this.socket.emit('input', param);
  // }

  // getSupportRequest() {
  //     return Observable.create((observer) => {
  //         this.socket.on('newRequestComing', (count) => {
  //             console.log('output message', count);
  //             observer.next(count);
  //         });
  //     });
  // }

  // socketoff(listner) {
  //     this.socket.removeAllListeners(listner);
  // }

  socketon() {
    this.socket.connect();
    console.log('this.socket', this.socket);
  }

  socketend() {
    this.socket.disconnect();
  }
}
